import React, { useCallback } from 'react';
import { Button } from '@csdental/react-components';
import { Trans,useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useAppSelector } from '@/common/hooks/state';
import authService from '@/features/auth/authService';
import authLayout from '@/components/AuthLayout';

const Login = () => {
  const { t } = useTranslation();

  // Get the timed out value from auth state
  const timedOut = useAppSelector((state) => state.auth.timedOut);

  const doLogin = useCallback(() => {
    authService.signIn();
  }, []);
  const component = (props: any) => {
    return (
      <div>
        <section className="loginMessage mt-40">
          {timedOut && (
            <Typography>
              <strong>{t('Your session has timed out.')}</strong>
            </Typography>
          )}
          <Typography>
            <Trans>
              Login using your <strong>Carestream Dental</strong> account to
              access to this portal.
            </Trans>
          </Typography>
        </section>
        <div>
          <Button
            label={t('LOGIN')}
            onClick={doLogin}
            icon={<ExitToAppIcon />}
          />
        </div>
      </div>
    );
  };
  const Child = authLayout(component);
  return <Child />;
};

export default Login;

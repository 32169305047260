import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IRule } from './rulesTypes';
import { configurationService } from '@/services/configuration';
import { RuleType } from '@/common/enums/RuleType';
import { TCallerId } from '../callerId/callerIdsTypes';
import { TLocation } from '../ruleTemplates/ruleTemplatesTypes';

export const ruleApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Rule', 'Package'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        addRule: builder.mutation<
          IRule,
          {applicationId: string; version: string; ruleType: RuleType; callerIds: TCallerId[],locations:TLocation[]}
        >({
          query: (body) => ({
            url: endPoints(api).rules.root,
            method: 'POST',
            body,
          }),
          invalidatesTags: (rule) => [
            { type: 'Rule', id: 'LIST' },
            { type: 'Rule', recordId: rule?.recordId },
            { type: 'Package', recordId: rule?.packageId }
          ],
        }),
        updateRule: builder.mutation<
          IRule,
          {applicationId: string; version: string; ruleType: RuleType; callerIds: TCallerId[],locations:TLocation[]}
        >({
          query: (body) => ({
            url: endPoints(api).rules.root,
            method: 'PUT',
            body,
          }),
          invalidatesTags: (rule) => [
            { type: 'Rule', id: 'LIST' },
            { type: 'Rule', recordId: rule?.recordId },
            { type: 'Package', recordId: rule?.packageId }
          ],
        })
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useAddRuleMutation,
  useUpdateRuleMutation
} =
ruleApi;

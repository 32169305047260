import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { configurationService } from '@/services/configuration';
import { IRegion } from './regionTypes';

export const regionApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Region'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getAllRegions: builder.query<IRegion[], void>({
          query: () => endPoints(api).regions.root,
          transformResponse: (responseData: IRegion[]) => responseData,
          providesTags: ['Region'],
        }),
      };
    },
  });

export const {
  useGetAllRegionsQuery,
} = regionApi;

import Login from './Login';
import Home from './Home';
import RuleTemplates from './RuleTemplates';
import Versions from './Versions';
import Users from './Users';

export default {
  Login,
  Home,
  RuleTemplates,
  Versions,
  Users
};

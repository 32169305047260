import React from 'react';
import { Text, ModalDialog, Button } from '@csdental/react-components';
import { useTranslation } from 'react-i18next';
import { IRuleTemplate } from '@/features/ruleTemplates/ruleTemplatesTypes';
import './deleteRuleTemplateDlg.scss';

type DeleteRuleTemplateDlgProps = {
  show: boolean;
  template: IRuleTemplate | null;
  onClose: () => void;
  onDelete: () => void;
  errorMessage: string;
};

const DeleteRuleTemplateDlg: React.FC<DeleteRuleTemplateDlgProps> = ({
  show,
  template,
  onClose,
  onDelete,
  errorMessage,
}) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      show={show}
      title={t('Delete rule template')}
      className="delete-dialog"
    >
      <div className="delete-dialog__content">
        <div className="delete-dialog__text-wrapper">
          <div className="delete-dialog__info">
            <Text
              label={t('You are about to delete the following rule template')}
              classname="delete-dialog__info-text"
            />
            <Text label={template?.name} classname="delete-dialog__name" />
          </div>
          <div className="delete-dialog__additional-message">
            <Text
              label={t(
                'This will not impact the versions on which this template was applied.'
              )}
            />
          </div>
          {errorMessage && (
            <div className="delete-dialog__error-message">
              <Text
                label={errorMessage}
                classname="delete-dialog__error-text"
              />
            </div>
          )}
        </div>
        <div className="delete-dialog__actions">
          <Button label={t('Cancel')} theme="secondary" onClick={onClose} />
          <Button label={t('Yes, Delete')} theme="primary" onClick={onDelete} />
        </div>
      </div>
    </ModalDialog>
  );
};

export default DeleteRuleTemplateDlg;

import React from 'react';
import { Button } from '@csdental/react-components';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '@/common/hooks/state';
import authService from '@/features/auth/authService';
import { signOut } from '@/features/auth/authSlice';
import alertIcon from '@/assets/icons/alert.svg';
import authLayout from '@/components/AuthLayout';

export const Unauthorized = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(signOut({ timedOut: false }));
    authService.signOut();
  };
  const component = () => {
    return (
      <div className="login">
        <div className="alert">
          <img className="alert-icon" src={alertIcon} alt="Warning" />
          {t('You are not authorized to use this service.')}
        </div>

        <div className="centered">
          <Button label={t('Logout')} onClick={logout} />
        </div>
      </div>
    );
  };
  const Child = authLayout(component);
  return <Child />;
};

import React, { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ModalDialog } from '@csdental/react-components';

import { IApplication } from '@/features/applications/applicationsTypes';
import { IPackage } from '@/features/packages/packagesTypes';
import { IRuleTemplate } from '@/features/ruleTemplates/ruleTemplatesTypes';
import { useGetRuleTemplatesByApplicationQuery } from '@/features/ruleTemplates/ruleTemplatesAPI';
import { skipToken } from '@reduxjs/toolkit/dist/query';

type SelectRuleDlgProps = {
  onConfirm: (item: IPackage, ruleTemplate: IRuleTemplate) => void,
  onCancel: () => void,
  application: IApplication,
  item: IPackage,
  show: boolean,
  hasError: boolean,
  disabled: boolean
}

const SelectRuleDlg = ({
  onConfirm,
  onCancel,
  application,
  item,
  show,
  hasError,
  disabled
}: SelectRuleDlgProps) => {
  const { t } = useTranslation();

  // Load rule templates for the current application
  const { data: templates, isLoading } =
    useGetRuleTemplatesByApplicationQuery(application?.id ? {
      applicationId: application?.id || ''
    } : skipToken);

  const [ruleTemplate, setRuleTemplate] = useState<IRuleTemplate>();

  useEffect(() => {
    const firstRuleTemplate = (templates?.value && templates?.value.length > 0) ? templates?.value[0] : {} as IRuleTemplate;
    setRuleTemplate(firstRuleTemplate);
  }, [templates]);

  const [canApply, setCanApply] = useState<boolean>(false);

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const ruleTemplate = templates?.value.find(t => t.recordId === e.target.value);
    setCanApply(!disabled && e.target.value != "");
    setRuleTemplate(ruleTemplate);
  }

  const onApply = () => {
    setCanApply(false)
    onConfirm(item, ruleTemplate || {} as IRuleTemplate)
  }

  return (
    <ModalDialog show={show} title={t('Apply rule')}>
      <div>
        <div className="selectRuleDlg__info">
          <p>{t(`Select a rule template for`)}:</p>
          <p className="selectRuleDlg__version">{application.name} - {t('Version')} {item.version}</p>
          <div>
            <select
              className="csdInput selectRuleDlg__menu"
              onChange={(e) => onChange(e)}
            >
              <option disabled selected value="">
                {t('Select')}
              </option>
              {!isLoading && templates?.value.map((rule) => (
                <option
                  value={rule.recordId}
                  key={rule.recordId}
                >
                  {rule.name}
                </option>
              ))}
            </select>
          </div>
          {hasError && <p className="error-message">{t('An error occurred when trying to apply the rule to the version.')}</p>}
        </div>
        <div>
          <div className="selectRuleDlg__buttonGroup">
            <Button
              theme="secondary"
              className="selectRuleDlg__buttonGroup__button"
              label={t('Cancel')}
              onClick={onCancel}
            />
            <Button
              className="selectRuleDlg__buttonGroup__button"
              label={t('Apply')}
              onClick={() =>onApply()}
              disable={!canApply}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default SelectRuleDlg;

import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '@/common/hooks/state';

import { Header } from '@csdental/react-components';
import AppInfoDlg from '@/components/AppInfoDlg';

import { useTranslation } from 'react-i18next';

import authService from '@/features/auth/authService';
import { signOut } from '@/features/auth/authSlice';
import {
  useGetAccountQuery,
  useUpdateSettingsMutation
} from '@/features/account/accountApi';

import { IHealthCheckProps } from '@/common/types/dialogTypes';

import { configurationService } from '@/services/configuration';

import appLogo from '@/assets/images/cd-app-logo.svg';
import headerBackground from '@/assets/images/header-bg.png';

export function getUserInitials(name: string) {
  return name
    .split(' ')
    .map((text) => text[0])
    .join('')
    .toUpperCase();
}

const HeaderComponent: React.FC = () => {
  const { t, i18n } = useTranslation();

  // Get the account of the logged in user
  const { data: account, isLoading } = useGetAccountQuery();

  const currentLanguage = account?.settings?.language ?? i18n.language.slice(0, 2);

  const dispatch = useAppDispatch();

  // Update User Settings Api trigger
  const [updateSettingsTrigger] = useUpdateSettingsMutation();

  // Show application info dialog
  const [showAppInfoDlg, setShowAppInfoDlg] = useState<boolean>(false);

  const logout = () => {
    dispatch(signOut({ timedOut: false }));
    authService.signOut();
  };

  const changeLanguage = (code: string) => {
    i18n.changeLanguage(code);
    // Update existing settings or create ones if the chosen language is not the default
    if (account?.settings?.language || code !== 'en') {
      updateSettingsTrigger({ ...account?.settings, userId: account?.recordId, language: code });
    }
  };

  // Set the current language
  if (account?.settings?.language && account?.settings?.language !== currentLanguage) {
    i18n.changeLanguage(account?.settings.language);
  }

  // If default language of the browser in not English, update user's settings
  useEffect(() => {
    changeLanguage(currentLanguage);
}, [currentLanguage]);

  const avatar = (): string => {
    if (account) {
      return account.recordId !== null && account.recordId !== undefined
        ? `${account?.firstName?.charAt(0)}${account?.lastName?.charAt(0)}`
        : 'U';
    }
    return 'U';
  };

  const accountInfo = {
    userDisplayName: `${account?.firstName} ${account?.lastName}`,
    infoLabel: t('Info'),
    logoutLabel: t('Logout'),
    userAvatar: avatar(),
    onOpenAppInfo: () => setShowAppInfoDlg(true),
    onLogout: logout,
  };

  const configuration = configurationService.getConfigDetails();
  const appConfigInfo: IHealthCheckProps = {
    items: [
      { title: 'Suite version', value: configuration.suiteVersion },
      { title: 'Suite commit date', value: configuration.commitDate }
    ]
  };

  const languagesInfo = {
    languages: [
      {
        value: 'English',
        code: 'en',
      },
      {
        value: 'Français',
        code: 'fr',
      },
    ],
    currentLanguage,
    OnChangeLanguage: changeLanguage,
  };
  if (isLoading) {
    return <></>;
  }
  return (
    <>
      <Header
        applicationName={t('SUMS Server administration portal')}
        logo={appLogo}
        backgroundImage={headerBackground}
        accountProps={accountInfo}
        languagesProps={languagesInfo}
      />
      <AppInfoDlg
        healthCheckInfo={appConfigInfo}
        show={showAppInfoDlg}
        onClose={() => setShowAppInfoDlg(false)}
      />
    </>
  );
};

export default HeaderComponent;

import React, { ComponentType } from 'react';
import csdLogo from '@/assets/images/csd-logo.svg';

export function authLayout<P>(
  WrappedComponent: ComponentType<P>,
  wrapperComponentProps?: P
): ComponentType<P> {
  return (props) => {
    const hocProps = { ...props, ...wrapperComponentProps };
    return (
      <div className="login">
        <div className="csdFlow centered">
          <div className="csdLogo">
            <img className="logo" src={csdLogo} alt="Carestream Dental Logo" />
            <WrappedComponent {...(hocProps as any)} />
          </div>
        </div>
      </div>
    );
  };
}

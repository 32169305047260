import { persistStore } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import { configureStore } from '@reduxjs/toolkit';
import session from 'redux-persist/lib/storage/session';
import authReducer from '@/features/auth/authSlice';
import { apiSlice } from '@/api/apiSlice';

const authPersistConfig = {
  key: 'auth',
  storage: session,
};

// Setup persisted storage for auth state to ensure
// the site loads correctly on a page refresh.
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    // There appear to be some serialisation issues in RTK which
    // logs errors to the console. Setting 'serialzableCheck: False'
    // surpresses these errors.
    serializableCheck: false,
  }).concat(apiSlice.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const persistor = persistStore(store);

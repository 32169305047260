import React from 'react';
import { LocationType } from '@/common/enums/RuleType';
import { useTranslation } from 'react-i18next';
import { mapLocationType } from '@/common/helpers/mapper';
import { IconButton, TextInput } from '@csdental/react-components';
import DeleteIcon from '@/assets/icons/delete.svg';
import { TLocation } from '@/features/ruleTemplates/ruleTemplatesTypes';
import { IRegion } from '@/features/regions/regionTypes';
type GeographicalFormProps = {
  data?: TLocation;
  regions: IRegion[];
  countries: string[];
  onClick: (
    ev: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => void | undefined;
  onLocationChanged: (
    key: string,
    value: LocationType | string | number | undefined,
    index: number | undefined
  ) => void | undefined;
  index?: number;
  onDelete: (index: number) => void;
  regionsSelected: string[];
  countriesSelected: string[];
};

const GeographicalForm: React.FC<Partial<GeographicalFormProps>> = ({
  data,
  regions,
  countries,
  onLocationChanged,
  index,
  onDelete,
  regionsSelected,
  countriesSelected,
}) => {
  const { t } = useTranslation();

  const countriesSelectedMap = () => {
    const countriesWithinRegionSelected = [
      ...new Set(
        regions
          ?.filter((region, _) => regionsSelected?.includes(region.name))
          ?.flatMap((region) => region.countries)
      ),
    ];
    return [
      ...new Set([...countriesSelected!, ...countriesWithinRegionSelected]),
    ];
  };
  const regionsSelectedMap = () => {
    const regionSelectedSet = new Set<string>();
    const contriesSelected = countriesSelectedMap();
    if (contriesSelected && contriesSelected.length) {
      for (let i = 0; i < contriesSelected?.length; i++) {
        const findRegion = regions?.find((region) =>
          region.countries.includes(contriesSelected[i])
        );
        if (findRegion) {
          regionSelectedSet.add(findRegion.name);
        }
      }
    }
    return [...new Set([...regionsSelected!]), ...regionSelectedSet];
  };
  const handlePercentageInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (!value) {
      onLocationChanged?.('percentage', '', index);
      return;
    }
    if (/[-+e]/.test(value)) {
      return;
    }
    const numericValue = parseInt(value);
    if (numericValue >= 0 && numericValue <= 100) {
      onLocationChanged?.('percentage', numericValue, index);
    }
  };
  const disabledCountries = countriesSelectedMap();
  const disabledRegions = regionsSelectedMap();
  let options;
  switch (data?.locationType) {
    case LocationType.Country:
      options = countries?.map((e) => ({
        value: e,
        label: t(e),
        disabled:
          data?.locationType === LocationType.Country
            ? disabledCountries.includes(e)
            : disabledRegions.includes(e),
      }));
      break;
    case LocationType.Region:
      options = regions?.map((region) => ({
        value: region.name,
        label: t(region.name),
        disabled:
          data?.locationType === LocationType.Country
            ? disabledCountries.includes(region.name)
            : disabledRegions.includes(region.name),
      }));
      break;
    default:
      options = [{ value: '', label: '', disabled: false }];
      break;
  }
  return (
    <div className="geoGraphicalFormContainer">
      <select
        className="csdInput addEditRuleTemplateContainer__select"
        value={data?.locationType}
        onChange={(ev) => {
          onLocationChanged &&
            onLocationChanged('locationType', ev.target.value, index);
          ev.preventDefault();
        }}
      >
        {Object.values(LocationType).map((el: LocationType) => (
          <option
            className="addEditRuleTemplateContainer__select__option"
            key={el}
            value={el}
            disabled={el === LocationType.Empty}
            hidden={el === LocationType.Empty}
          >
            {t(mapLocationType(el)!)}
          </option>
        ))}
      </select>

      <TextInput
        inputClassName="geoGraphicalFormContainer__TextInput__input"
        containerClassName="geoGraphicalFormContainer__TextInput"
        labelContainerClassName="geoGraphicalFormContainer__TextInput__label"
        placeHolder={t('Select')}
        value={data?.name!}
        options={options?.sort((a, b) => ('' + a.label).localeCompare(b.label))}
        onClick={(
          ev: React.MouseEvent<HTMLLIElement, MouseEvent>,
          _: number
        ) => {
          const { value } = ev.currentTarget.dataset;
          onLocationChanged && onLocationChanged('name', value, index);
        }}
      />
      <div className="geoGraphicalFormContainer__percentage">
        <input
          id="percentage"
          className="csdInput"
          value={data?.percentage !== undefined ? data?.percentage : ''}
          onChange={handlePercentageInput}
        />
        <span className="ml-8">%</span>
      </div>
      <IconButton
        onClick={() => {
          onDelete && onDelete(index!);
        }}
        alt="Delete"
        img={DeleteIcon}
      />
    </div>
  );
};

export default GeographicalForm;

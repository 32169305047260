import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { signInSuccess, signOut } from '@/features/auth/authSlice';
import type { RootState } from '@/store';
import { configurationService } from '@/services/configuration';
import authService from '@/features/auth/authService';

/** Set the base query with default headers */
const baseQuery = (args: any, api: any, extraOptions: any) => {
  const apiConfig = configurationService.getApiEndpoints();
  return fetchBaseQuery({
    baseUrl: apiConfig.host,
    prepareHeaders: (headers, { getState }) => {
      // Get the access token from state
      const { token } = (getState() as RootState).auth;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json;charset=utf-8');
      return headers;
    },
  })(args, api, extraOptions);
};

/** Base query to refresh the auth token on failure */
export const baseQueryWithRefresh = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: {},
) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    const refreshResult = await authService.getUserStandardAuthToken();

    if (refreshResult) {
      // Update the auth state
      api.dispatch(signInSuccess({ token: refreshResult }));
      // Rerun the query with the newly acquired token
      result = await baseQuery(args, api, extraOptions);
    } else {
      // Failed to get a new token so log out
      api.dispatch(signOut({ timedOut: false }));
    }
  }
  return result;
};

/** Define our single API slice object */
export const apiSlice = createApi({
  baseQuery: baseQueryWithRefresh,
  // The "endpoints" represent operations and requests for this server
  // We will extend the API slice by injecting endpoints from features
  endpoints: () => ({}),
});

export default apiSlice;

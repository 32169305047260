import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IAccount, IAccountSettings } from './accountTypes';
import { configurationService } from '@/services/configuration';

export const accountApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Account', 'Settings'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getAccount: builder.query<IAccount, void>({
          query: () => endPoints(api).account.root,
          transformResponse: (responseData: IAccount) => responseData,
          providesTags: ['Account'],
        }),
        updateSettings: builder.mutation<IAccountSettings, Partial<IAccountSettings>>({
          query(data) {
            const { ...body } = data;
            return {
              url: endPoints(api).account.updateSettings,
              method: 'PUT',
              body,
            };
          },
          invalidatesTags: ['Account'],
        })
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useGetAccountQuery,
  useUpdateSettingsMutation,
} =
  accountApi;

import { TLocation } from '@/features/ruleTemplates/ruleTemplatesTypes';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { LocationType } from '../enums/RuleType';

/**
 * Type predicate to narrow an unknown error to `FetchBaseQueryError`
 */
export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

/**
 * Type predicate to narrow an unknown error to an object with a string 'message' property
 */
export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export function IsLocations(locations:TLocation[]) {
  return locations.every(location => 
      location.locationType !== LocationType.Empty &&
      location.name !== "" &&
      location.percentage !== "" &&
      location.percentage !== undefined 
  );
}
import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Button,
  ModalDialog,
  TextField,
} from '@csdental/react-components';
import {
  Box,
  DialogActions,
} from '@mui/material';

import { IUser } from '../../features/users/usersTypes';
import { useGetAccountQuery } from '@/features/account/accountApi';

import { IDialogProps } from '../../common/types/dialogTypes';

import isEmail from 'validator/lib/isEmail';

const AddEditUserDlg : React.FC<IDialogProps<IUser>> = ({ ...props }: IDialogProps<IUser>) => {
  const { t } = useTranslation();

  const defaultData: Partial<IUser> = {
    firstName: undefined,
    lastName: undefined,
    emailAddress: undefined,
    role: "Admin"
  };

  const defaultError = {
    firstNameError : false,
    lastNameError : false,
    emailError : false
  }; 

  const [data, setData] = useState<Partial<IUser>>(defaultData);
  const [error, setError] = useState<{
    firstNameError: boolean;
    lastNameError: boolean;
    emailError: boolean;
  }>({ ...defaultError });

  const [validated, setValidated] = useState<boolean>(false);

  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();

  // Update edited user with input data
  useEffect(() => {
    const data = props.data as IUser | null;
    if (data) {
      setData({ ...data });
    }
  }, [props.data]);

  // Update errors on inputs change
  useEffect(() => {
    let currentErrors = defaultError;

    if (undefined !== data.firstName)
      currentErrors.firstNameError = data.firstName.length === 0;

    if (undefined !== data.lastName)
      currentErrors.lastNameError = data.lastName.length === 0;

    if (undefined !== data.emailAddress)
      currentErrors.emailError = !isEmail(data.emailAddress);

    setError(currentErrors);
    setValidated(
      !currentErrors.firstNameError && data.firstName !== undefined &&
      !currentErrors.lastNameError && data.lastName !== undefined &&
      !currentErrors.emailError
    )
  }, [data]);

  // Reset form data when exiting dialog
  const reset = () => {
    setData({ ...defaultData });
    setError({ ...defaultError });
  };

  return (
    <ModalDialog
      show={props.show}
      title={props.type === 'Create' ? t('Add user') : t('Edit user')}>
      <div className="add-edit-user">
        <div className="add-edit-user__legend">
          <span>* </span>
          {t('Mandatory fields')}
        </div>
        <Box
          component="form"
          sx={{ maxWidth: '100%' }}
          noValidate
          autoComplete="off"
        >
          <TextField
            value={data.firstName}
            error={error.firstNameError}
            fullWidth
            required
            margin="normal"
            size="small"
            id="user-firstname"
            label={t('First name')}
            variant="outlined"
            onChange={(e) => setData({ ...data, firstName: e.target.value })}
            helperText={error.firstNameError ? t('The first name is required.') : ''}
          />

          <TextField
            value={data.lastName}
            error={error.lastNameError}
            fullWidth
            required
            margin="normal"
            size="small"
            id="user-lastname"
            label={t('Last name')}
            variant="outlined"
            onChange={(e) => setData({ ...data, lastName: e.target.value })}
            helperText={error.lastNameError ? t('The last name is required.') : ''}
          />

          <TextField
            value={data.emailAddress}
            error={error.emailError}
            fullWidth
            required
            margin="normal"
            size="small"
            id="user-email"
            label={t('Email')}
            variant="outlined"
            onChange={(e) => setData({ ...data, emailAddress: e.target.value })}
            helperText={error.emailError ? t('The email is invalid.') : ''}
            disabled={props.data?.emailAddress === account?.emailAddress}
          />
          {
            ((props.error?.toLowerCase().includes('already exists'))) ?
              <p className="add-edit-user__error-text">
                {t('The user already exists.')}
              </p>
              :
              ((props.error?.length && props.error?.length > 0) ?
                <p className="add-edit-user__error-text">
                  {t('An error occurred when trying to add or edit the user.')}
                </p>
                :
                <p></p>
              )
          }

          <DialogActions>
            <Button
              label={t('Cancel')}
              onClick={() => {
                props.onCancel?.call(this);
                reset();
              }}
              theme="secondary"
            />
            <Button
              label={t('Save')}
              disable={!validated}
              onClick={() => {
                props.type === 'Create'
                  ? props.onCreate?.call(this, {
                    data: data,
                    reset: reset
                  })
                  : props.onEdit?.call(this, {
                    data: data,
                    reset: reset
                  });
              }}
            />
          </DialogActions>
        </Box>
      </div>
    </ModalDialog>
  );
};

export default AddEditUserDlg;

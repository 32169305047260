import React from 'react';

export type TooltipProps =
{
  text: string;
  icon?: string;
}

export const Tooltip = ({text, icon}: TooltipProps) => {
  return (
    <span className="customTooltip p-10">
      {icon ?
        <img
          src={icon}
          alt="Icon for tooltip"
          className="customTooltip__icon mr-10"
        />
        : <></>
      }
      {text}
    </span>
  );
}

export default Tooltip;

import { LocationType, RuleType } from '@/common/enums/RuleType';
import { mapLicenseType, mapRuleType } from '@/common/helpers/mapper';
import { IDialogProps } from '@/common/types/dialogTypes';
import {
  IRuleTemplate,
} from '@/features/ruleTemplates/ruleTemplatesTypes';
import { useValidateCallerIdMutation } from '@/features/callerId/callerIdsAPI';
import { Button, ModalDialog, TextInput } from '@csdental/react-components';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '@/components/Loader';
import GeographicalForm from '../GeographicalForm';
import { useGetAllRegionsQuery } from '@/features/regions/regionsAPI';
import { IsLocations } from '@/common/helpers/errorTypeChecker';

const defaultRuleTemplateDataError = {
  nameError: false,
  ruleTypeError: false,
  callerIdsError: [],
  nameUniqueError: false,
};
const defaultRuleTemplateData: Partial<IRuleTemplate> = {
  name: '',
  callerIds: [],
  ruleType: RuleType.Empty,
  locations: [
    {
      locationType: LocationType.Empty,
      name: '',
    },
  ],
};
const AddEditRuleTemplateDlg: React.FC<IDialogProps<IRuleTemplate>> = ({
  ...props
}: IDialogProps<IRuleTemplate>) => {
  const { t } = useTranslation();
  // Validate CallerId Api trigger
  const [validateCallerIdTrigger] = useValidateCallerIdMutation();
  const { data: regions, isLoading } = useGetAllRegionsQuery();
  const countries = isLoading
    ? []
    : [...new Set(regions?.flatMap((region) => region.countries))];
  const [ruleTemplateData, setRuleTemplateData] = useState<
    Partial<IRuleTemplate>
  >(defaultRuleTemplateData);
  const [ruleTemplateDataError, setRuleTemplateDataError] = useState<{
    nameError: boolean;
    ruleTypeError: boolean;
    callerIdsError: string[];
    nameUniqueError: boolean;
  }>({ ...defaultRuleTemplateDataError });
  const [isInputhasValue, setIsInputhasValue] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const geoGraphContainerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const data = props.data as IRuleTemplate | null;
    if (data) {
      if(data.locations && data.locations?.length === 0){
        data.locations = [
          {
            locationType: LocationType.Empty,
            name: '',
          },
        ];
      }
      setRuleTemplateData({
        ...defaultRuleTemplateData,
        ...data,
      });
    }
  }, [props.data]);
  const inputChange = (key: string, value: any) => {
    setRuleTemplateData({
      ...ruleTemplateData!,
      [key]: value,
    });
    setRuleTemplateDataError({
      ...ruleTemplateDataError,
      [`${key}Error`]: value ? false : true,
    });
  };
  const onEnter = async (values: string[], val: string) => {
    const licenseKeys = val
      .toUpperCase()
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item.trim() !== '');
    try {
      setLoading(true);
      const applicationId = props.getApplicationId?.call(this);
      const results = await Promise.all(
        licenseKeys.map((el) =>
          validateCallerIdTrigger({
            Value: el,
            LicenseType: mapLicenseType(applicationId),
          })
        )
      );
      const errorLicenseKeys = licenseKeys.filter(
        // handle multiple error api call
        // "'error' in result" is meant to check if there is any error key inside the result object
        // check FetchBaseQueryError type
        (_, index) => 'error' in results[index]
      );
      if (errorLicenseKeys.length) {
        setRuleTemplateDataError({
          ...ruleTemplateDataError,
          callerIdsError: [
            ...ruleTemplateDataError.callerIdsError,
            ...errorLicenseKeys,
          ],
        });
      }
    } catch (_) {
      setRuleTemplateDataError({
        ...ruleTemplateDataError,
        callerIdsError: [
          ...ruleTemplateDataError.callerIdsError,
          ...licenseKeys,
        ],
      });
    } finally {
      setLoading(false);
    }
    setRuleTemplateData({
      ...ruleTemplateData!,
      callerIds: [
        ...values.map((el) => ({
          value: el,
        })),
        ...licenseKeys.map((el) => ({
          value: el,
        })),
      ],
    });
    setIsInputhasValue(false);
  };
  const onBackspace = async (_: string[]) => {
    const callerIds = [...ruleTemplateData?.callerIds!];
    const callerIdsError = [...ruleTemplateDataError.callerIdsError];
    callerIds?.pop();
    callerIdsError?.pop();
    setRuleTemplateData({
      ...ruleTemplateData!,
      callerIds: [...callerIds],
    });
    setRuleTemplateDataError({
      ...ruleTemplateDataError,
      callerIdsError: [...callerIdsError],
    });
  };
  const onIconClick = async (value: string, index: number) => {
    const indexOfError = ruleTemplateDataError?.callerIdsError.indexOf(value);
    if (indexOfError !== -1) {
      const callerIdsError = [...ruleTemplateDataError.callerIdsError];

      callerIdsError.splice(indexOfError!, 1);
      setRuleTemplateDataError({
        ...ruleTemplateDataError,
        callerIdsError: [...callerIdsError],
      });
    }
    const callerIds = [...ruleTemplateData?.callerIds!];
    callerIds.splice(index, 1);
    setRuleTemplateData({
      ...ruleTemplateData!,
      callerIds: [...callerIds],
    });
  };
  const onClearClick = async (value: string[]) => {
    setRuleTemplateData({ ...ruleTemplateData!, callerIds: [] });
    setRuleTemplateDataError({ ...ruleTemplateDataError, callerIdsError: [] });
  };
  const setInput = async (
    inputRef: React.MutableRefObject<HTMLInputElement | null>
  ) => {
    if (inputRef.current?.value) {
      inputRef.current.value = '';
    }
  };
  const validateData = () => {
    return ruleTemplateData.ruleType == RuleType.CallerId
      ? ruleTemplateDataError.callerIdsError.length === 0 &&
          !ruleTemplateDataError.nameError &&
          !ruleTemplateDataError.ruleTypeError &&
          !isInputhasValue &&
          ruleTemplateData?.name &&
          ruleTemplateData?.ruleType &&
          ruleTemplateData?.callerIds?.length !== 0
      : !ruleTemplateDataError.nameError &&
          !ruleTemplateDataError.ruleTypeError &&
          ruleTemplateData?.name &&
          ruleTemplateData?.ruleType &&
          ruleTemplateData?.locations?.length !== 0 &&
          IsLocations(ruleTemplateData?.locations!);
  };
  const resetState = () => {
    setRuleTemplateData({ ...defaultRuleTemplateData });
    setRuleTemplateDataError({ ...defaultRuleTemplateDataError });
  };
  const invalidLicenseKeys = ruleTemplateDataError.callerIdsError.join(' ');
  const TextInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsInputhasValue(event.target.value !== '');
  };
  const onLocationChanged = (
    key: string,
    value: LocationType | string | number | undefined,
    index: number | undefined
  ) => {
    const locations = [...ruleTemplateData.locations!];
    if (value !== undefined && index !== undefined) {
      if (key == 'locationType') {
        locations[index] = {
          ...locations[index],
          name: '',
        };
      }
      locations[index] = {
        ...locations[index],
        [key]: value,
      };
    }
    setRuleTemplateData({
      ...ruleTemplateData,
      locations,
    });
  };
  const onDelete = (index: number) => {
    const locations = [...ruleTemplateData.locations!];
    if (locations.length !== 1) {
      locations.splice(index, 1);
      setRuleTemplateData({
        ...ruleTemplateData,
        locations,
      });
    }
  };
  useEffect(() => {
    if (ruleTemplateData.locations?.length) {
      geoGraphContainerRef.current?.scrollTo({
        top: geoGraphContainerRef.current?.scrollHeight,
      });
    }
  }, [ruleTemplateData.locations]);
  const onSubRuleClicked = () => {
    const locations = [...ruleTemplateData?.locations!];
    const lastLocation = locations[locations.length - 1];
    if (
      !(
        lastLocation.locationType == LocationType.Empty ||
        lastLocation.name == '' ||
        lastLocation.percentage == null ||
        lastLocation.percentage == undefined
      )
    ) {
      locations.push({
        locationType: LocationType.Empty,
        name: '',
      });
      setRuleTemplateData({
        ...ruleTemplateData,
        locations,
      });
    }
  };
  const createEditClicked = async () => {
    try {
      props.type === 'Create'
        ? await props.onCreate?.call(this, {
            data: ruleTemplateData,
            reset: resetState,
          })
        : await props.onEdit?.call(this, {
            data: ruleTemplateData,
            reset: resetState,
          });
    } catch (ex) {
      setRuleTemplateDataError({
        ...defaultRuleTemplateDataError,
        nameUniqueError: true,
      });
    }
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <ModalDialog
        classes={{ paper: 'addEditRuleTemplate__paper' }}
        show={props.show}
        title={props.title}
      >
        <div className="addEditRuleTemplateContainer">
          <section>
            <label className="addEditRuleTemplateContainer__label">
              {t('Rule name')}
            </label>
            <input
              className="csdInput addEditRuleTemplateContainer__input"
              value={ruleTemplateData?.name}
              onChange={(e) => inputChange('name', e.target.value)}
            />
            {ruleTemplateDataError.nameError ? (
              <p className="csdError">{t('Rule template name is required')}</p>
            ) : ruleTemplateDataError.nameUniqueError ? (
              <p className="csdError">{t('Rule template name is unique')}</p>
            ) : (
              <></>
            )}
            <label className="addEditRuleTemplateContainer__label mt-25">
              {t('Rule type')}
            </label>
            <select
              className="csdInput addEditRuleTemplateContainer__select"
              value={ruleTemplateData?.ruleType}
              onChange={(e) => inputChange('ruleType', e.target.value)}
            >
              {Object.values(RuleType).map((el: RuleType) => (
                <option
                  className="addEditRuleTemplateContainer__select__option"
                  key={el}
                  value={el}
                  disabled={el === RuleType.Empty}
                  hidden={el === RuleType.Empty}
                >
                  {t(mapRuleType(el)!)}
                </option>
              ))}
            </select>
            {ruleTemplateDataError.ruleTypeError ? (
              <p className="csdError">{t('Rule template type is required')}</p>
            ) : (
              <></>
            )}
            {ruleTemplateData?.ruleType === RuleType.CallerId ? (
              <div className="mt-25">
                <TextInput
                  values={ruleTemplateData?.callerIds?.map((el) => el.value)}
                  errorValues={ruleTemplateDataError.callerIdsError}
                  onEnter={onEnter}
                  onBackspace={onBackspace}
                  onIconClick={onIconClick}
                  onClearClick={onClearClick}
                  label={t('Enter license key')}
                  clear={t('Clear')}
                  setInput={setInput}
                  containerClassName="addEditRuleTemplateContainer__textInputContainer"
                  inputClassName={
                    ruleTemplateDataError.callerIdsError.length
                      ? 'addEditRuleTemplateContainer__textInputContainer--error'
                      : ''
                  }
                  onChange={TextInputChange}
                />
                {ruleTemplateDataError.callerIdsError.length ? (
                  <p className="csdError">
                    {t(`Licenses {{invalidLicenseKeys}} are not valid`, {
                      invalidLicenseKeys,
                    })}
                  </p>
                ) : (
                  <></>
                )}
                <p className="addEditRuleTemplateContainer__textInput__text mt-5 mb-0">
                  {t(
                    'Supports multiple license keys with a comma separator. Press “enter” to confirm.'
                  )}
                </p>
              </div>
            ) : ruleTemplateData?.ruleType == RuleType.LocationAndPercentage ? (
              <>
                <span className="geopraphicalContainer__volume">
                  {t('Volume')}
                </span>
                <div
                  ref={geoGraphContainerRef}
                  className="geopraphicalContainer"
                >
                  {ruleTemplateData.locations?.map((location, index) => {
                    return (
                      <GeographicalForm
                        countries={countries}
                        regions={regions}
                        key={index}
                        data={location}
                        onLocationChanged={onLocationChanged}
                        onDelete={onDelete}
                        index={index}
                        regionsSelected={ruleTemplateData.locations
                          ?.filter(
                            (location, index) =>
                              location.name !== '' &&
                              location.locationType === LocationType.Region
                          )
                          ?.map((location) => location.name)}
                        countriesSelected={ruleTemplateData.locations
                          ?.filter(
                            (location, index) =>
                              location.name !== '' &&
                              location.locationType === LocationType.Country
                          )
                          ?.map((location) => location.name)}
                      />
                    );
                  })}
                  <Button
                    className="geopraphicalContainer__button"
                    label={`+ ${t('Add sub-rule')}`}
                    onClick={onSubRuleClicked}
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </section>
          <section className="addEditRuleTemplateContainer__footer">
            <Button
              label={t('Cancel')}
              theme="secondary"
              onClick={() => {
                props.onCancel?.call(this);
                resetState();
              }}
            />
            <Button
              label={t(props.type)}
              theme="primary"
              disable={!validateData()}
              onClick={createEditClicked}
            />
          </section>
        </div>
      </ModalDialog>
    </>
  );
};

export default AddEditRuleTemplateDlg;

import React, { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ModalDialog} from '@csdental/react-components';

import { IRuleTemplate } from '@/features/ruleTemplates/ruleTemplatesTypes';
import { IRule } from '@/features/rules/rulesTypes';
import { TCallerId } from '@/features/callerId/callerIdsTypes';

import searchIcon from '@/assets/icons/search.svg'

type CallerIdDetailsDlgProps = {
  item: IRuleTemplate | IRule,
  show: boolean,
  onClose: () => void
}

const CallerIdDetailsDlg = ({
  item,
  show,
  onClose
} : CallerIdDetailsDlgProps) => {
  const { t } = useTranslation();

  const [callerIds, setCallerIds] = useState<TCallerId[]>([]);

  useEffect(() => {
    if (item.callerIds)
      setCallerIds(item.callerIds);
  }, [item.callerIds]);

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const matchingCallerIds = item.callerIds?.filter(ci => String(ci.value).includes(e.target.value)) || [];
    setCallerIds(matchingCallerIds);
  };

  return (
    <ModalDialog
      show={show}
      title={t('License keys')}
    >
      <div className='callerIdDetailsDlg__content ml-20 mr-20'>
        <section>
          <div className='callerIdDetailsDlg__header mb-5'>
            <p className='callerIdDetailsDlg__text'>
              {callerIds ?
                t('license key', { count: callerIds.length })
                : ''}
            </p>
            <div className='callerIdDetailsDlg__searchBar'>
              <img
                src={searchIcon}
                className='callerIdDetailsDlg__searchBar__icon'
              />
              <input
                className='callerIdDetailsDlg__searchBar__input pl-10'
                type="text"
                placeholder={t('Search license keys')}
                onChange={onSearch}
              />
            </div>
          </div>
          <div className='callerIdDetailsDlg__wrapper'>
            <ul className='callerIdDetailsDlg__list'>
              {callerIds?.map((callerId: any, index: number) => (
                <li
                  key={index}
                  className='callerIdDetailsDlg__item'
                >
                  {callerId.value}
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section>
          <div className='callerIdDetailDlg__buttonContainer mt-50'>
            <Button
              theme="primary"
              label={t('Close')}
              onClick={onClose}
            />
          </div>
        </section>
      </div>
    </ModalDialog>
  );
};

export default CallerIdDetailsDlg;

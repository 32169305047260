import { csImagingSuiteId } from '@/constants';
import { LocationType, RuleType } from '../enums/RuleType';
import { LicenseType } from '../enums/LicenseType';

export const mapRuleType = (ruleType: RuleType) => {
  if (ruleType === RuleType.CallerId) {
    return 'License key';
  }
  if (ruleType === RuleType.Empty) {
    return 'Select';
  }
  if(ruleType === RuleType.LocationAndPercentage){
    return 'Geographical zone';
  }
};
export const mapLocationType = (locationType: LocationType) => {
  if (locationType === LocationType.Empty) {
    return 'Select';
  }
  if (locationType === LocationType.Country) {
    return 'Country';
  }
  if(locationType === LocationType.Region){
    return 'Region';
  }
};

export const mapLicenseType = (applicationId: string) => {
  if (applicationId === csImagingSuiteId) {
    return LicenseType.CsImaging;
  }
  return '';
};

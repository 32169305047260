export enum Status {
    Published = 'Published',
    Released = 'Released',
    Cancelled = 'Cancelled',
    Paused = 'Paused',
    CheckFailed = 'CheckFailed',
    CheckInProgress = 'CheckInProgress'
  }

  const getActionFromStatus = (status: Status) => {
    let action = '';
    switch (status) {
      case Status.Released:
        action = 'Release';
        break;
      case Status.Paused:
        action = 'Pause';
        break;
      case Status.Cancelled:
        action = 'Cancel';
        break;
      default:
        break;
    }
    return action;
  }

  export {getActionFromStatus}

import { LocationType } from '@/common/enums/RuleType';
import { TLocation } from '@/features/ruleTemplates/ruleTemplatesTypes';
import React from 'react';
import { useTranslation } from 'react-i18next';

type GeographicalRuleDetailsProps = {
  data?: TLocation[];
};

const GeographicalRuleDetails: React.FC<GeographicalRuleDetailsProps> = ({
  data,
}) => {
    const { t } = useTranslation();
  return (
    <div>
      {data?.map((location, index) => {
        const locationName = location.locationType===LocationType.Region? location.name: t(location.name)
        return (
          <div key={index}>{`${locationName} : ${location.percentage}%`}</div>
        );
      })}
    </div>
  );
};

export default GeographicalRuleDetails;

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from '@/components/AppRoutes';
import AuthProvider from '@/features/auth';

const App: React.FC = () => (
  <div className="app">
    <AuthProvider logMessages>
      <BrowserRouter basename="/">
        <AppRoutes />
      </BrowserRouter>
    </AuthProvider>
  </div>
);

export default App;

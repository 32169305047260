import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Button } from '@csdental/react-components';

export interface IdleTimerPromptProps {
  /**
   * Open the timeout prompt
   */
  open: boolean;
  /**
   * Number of seconds remaining before a timeout occurs
   */
  countdown: number;
  /**
   * Logout method
   */
  onLogout?: () => void;
  /**
   * Continue method
   */
  onContinue?: () => void;
}

export default function IdleTimerPrompt({ ...inputProps }: IdleTimerPromptProps) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={inputProps.open}
      aria-labelledby="session-timeout-dialog"
      aria-describedby="session-timeout-dialog"
    >
      <DialogTitle id="session-timeout-dialog-title">
        {t('Session Timeout')}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2">
          <Trans i18nKey="sessionCountdown">
            {{ seconds: inputProps.countdown.toString() }}
          </Trans>
        </Typography>
        <Typography variant="body2">{t('Would you like to continue the session?')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          label={t('Logout')}
          onClick={inputProps.onLogout}
        />
        <Button
          label={t('Continue')}
          onClick={inputProps.onContinue}
        />
      </DialogActions>
    </Dialog>
  );
}

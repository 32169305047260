import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { IAuthState, ISignInSuccessPayload, ISignOutPayload } from './authTypes';

const initialState: IAuthState = {
  token: '',
  loading: true,
  success: false,
  error: false,
  timedOut: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state: IAuthState) => {
      state.token = '';
      state.loading = true;
    },
    signInSuccess: (state, action: PayloadAction<ISignInSuccessPayload>) => {
      state.token = action.payload.token;
      state.loading = false;
      state.success = true;
      state.timedOut = false;
    },
    signOut: (state, action: PayloadAction<ISignOutPayload>) => {
      state.token = '';
      state.loading = false;
      state.success = false;
      state.timedOut = action.payload.timedOut;
    },
  },
});

// Action creators are generated for each case reducer function
export const { signIn, signInSuccess, signOut } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentToken = (state: RootState) => state.auth.token;

import {
  BrowserCacheLocation,
  Configuration,
  LogLevel,
} from '@azure/msal-browser';

type IAuth = {
  loginDomain: string;
  clientId: string;
  tenantName: string;
  policies: {
    [key: string]: string;
  };
  scopes: {
    [key: string]: string;
  };
  consoleTextStyles: {
    Title: string;
    Unset: string;
  };
};
export type RawConfig = {
  auth: IAuth;
  api: { [key: string]: string };
  config: { [key: string]: string };
};

export class ConfigurationService {
  configuration: RawConfig;

  constructor() {
    this.configuration = window.ENV ;
  }

  public getConfiguration(): RawConfig {
    return this.configuration;
  }

  public getMSALConfig(): Configuration {
    const { clientId, loginDomain, tenantName, policies } =
      this.configuration.auth;
    const msalConfig = {
      auth: {
        clientId,
        authority: `https://${loginDomain}/${tenantName}.onmicrosoft.com/${policies?.login}`,
        knownAuthorities: [loginDomain],
        navigateToLoginRequestUrl: false,
        redirectUri: window.location.href,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.SessionStorage,
        storeAuthStateInCookie: false,
      },
      system: {
        loggerOptions: {
          loggerCallback: (
            level: LogLevel,
            message: string,
            containsPii: boolean
          ) => {
            if (containsPii) {
              return;
            }

            const logEntry = `%cMSAL%c ${message}`;

            switch (level) {
              case LogLevel.Error:
                console.error(logEntry);
                break;
              case LogLevel.Info:
                console.info(logEntry);
                break;
              case LogLevel.Verbose:
                console.debug(logEntry);
                break;
              case LogLevel.Warning:
                console.warn(logEntry);
                break;
              default: {
                break;
              }
            }
          },
        },
      },
    };

    return msalConfig;
  }

  public getApiEndpoints() {
    return this.configuration.api;
  }

  public getConfigDetails() {
    return this.configuration.config;
  }
}
const configtionService = new ConfigurationService();
export const configurationService = configtionService;

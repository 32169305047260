import React from 'react';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useAppDispatch } from '@/common/hooks/state';

import { Routes, Route, Navigate } from 'react-router-dom';
import HeaderComponent from '@/components/Header/Header';
import { IdleTimer } from '@/components/IdleTimer/IdleTimer';
import AuthentificationWrapper from '@/components/AuthentificationWrapper';
import pages from '@/page';

import { signOut } from '@/features/auth/authSlice';
import authService from '@/features/auth/authService';

const { Login, Home } = pages;

const NotLoggedIn = () => (
  <Routes>
    <Route path="/" element={<Login />} />
    <Route path="*" element={<Navigate to="/" replace />} />
  </Routes>
);
const LoggedIn = () => {
  
  const dispatch = useAppDispatch();
  const handleTimeout = (isTimedOut: boolean) => {
    dispatch(signOut({ timedOut: isTimedOut }));
    authService.signOut();
  };
  return (
    <div>
      <IdleTimer onTimeout={handleTimeout} />
      <HeaderComponent />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
};

const AppRoutes = () => (
  <div>

    <UnauthenticatedTemplate>
      <NotLoggedIn />
    </UnauthenticatedTemplate>
    <AuthenticatedTemplate>
      <AuthentificationWrapper>
        <LoggedIn />
      </AuthentificationWrapper>
    </AuthenticatedTemplate>
  </div>
);
export default AppRoutes;

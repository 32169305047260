import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IApplication } from './applicationsTypes';
import { configurationService } from '@/services/configuration';

export const applicationApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Application'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getApplication: builder.query<IApplication,
        {applicationId: string}>({
          query: (args) => {
            const params : Record<string, string> = {};
            params.applicationId = encodeURIComponent(args.applicationId);
            const queryParams = new URLSearchParams(params);

            const url = `${endPoints(api).applications.root}?${queryParams.toString()}`;
            return url;
          },
          providesTags: ['Application'],
        })
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useGetApplicationQuery
} =
  applicationApi;

export enum RuleType {
  Empty = '',
  CallerId = 'CallerId',

  LocationAndPercentage = "LocationAndPercentage"
}

export enum LocationType {
  Empty = '',

  Country = "Country",

  Region = "Region"
}
import React, { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { IconButton, Table, Text } from '@csdental/react-components';
import ActionsMenu from '@/components/ActionsMenu';
import ConfirmStatusDlg from '@/components/ConfirmStatusDlg';
import SelectRuleDlg from '@/components/SelectRuleDlg';
import CallerIdDetailsDlg from '@/components/CallerIdDetailsDlg';
import Tooltip from '@/components/Tooltip';

import {
  useGetPackagesByApplicationQuery,
  useUpdateStatusMutation,
  useGetPackageStatusQuery,
} from '@/features/packages/packagesAPI';
import { IPackage } from '@/features/packages/packagesTypes';
import {
  useGetAccountQuery,
  useUpdateSettingsMutation,
} from '@/features/account/accountApi';
import { IApplication } from '@/features/applications/applicationsTypes';
import { useGetApplicationQuery } from '@/features/applications/applicationsAPI';
import { IRuleTemplate } from '@/features/ruleTemplates/ruleTemplatesTypes';
import {
  useAddRuleMutation,
  useUpdateRuleMutation,
} from '@/features/rules/rulesAPI';
import { IRule } from '@/features/rules/rulesTypes';

import { Status } from '@/common/enums/status';
import { RuleType } from '@/common/enums/RuleType';

import { formatDate } from '@/utils/format';
import { defaultItemsPerPage } from '@/constants';
import {
  isErrorWithMessage,
  isFetchBaseQueryError,
} from '@/common/helpers/errorTypeChecker';

import editIcon from '@/assets/icons/edit.svg';
import callerIdDetailsIcon from '@/assets/icons/magnifying_glass.svg';
import warningStatusIcon from '@/assets/icons/warning_white.svg';
import warningTooltipIcon from '@/assets/icons/warning_red.svg';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import GeographicalRuleDetails from '@/components/GeographicalRuleDetails';

export type VersionsProps = {
  applicationId: string;
};

export const Versions = ({ applicationId }: VersionsProps) => {
  const { t } = useTranslation();

  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();

  // Get the current application
  const { data: application } = useGetApplicationQuery({
    applicationId: applicationId,
  });

  // Set the default search params
  const [searchParams, setSearchParams] = useState({
    rowsPerPage: defaultItemsPerPage,
    startOffset: 0,
  });

  // Load packages for the current application
  const {
    data: packages,
    isLoading,
    refetch,
  } = useGetPackagesByApplicationQuery({
    applicationId: applicationId,
    skip: searchParams.startOffset,
    top: searchParams.rowsPerPage,
  });
  // Refresh package status after releasing the package
  const [packageToUpdate, setPackageToUpdate] = useState<IPackage>();
  const [poolingInterval, setPoolingInterval] = useState<number>(0);

  const { data: updatedPackage } = useGetPackageStatusQuery(
    packageToUpdate ? { item: packageToUpdate } : skipToken,
    { pollingInterval: poolingInterval }
  );

  useEffect(() => {
    if (updatedPackage && updatedPackage.status !== Status.CheckInProgress) {
      // If package status has changed (to Released or CheckFailed)
      // Stop watching its status and refresh the packages list
      setPoolingInterval(0);
      refetch();
    }
  }, [updatedPackage, setPoolingInterval]);

  const getPage = (startOffset: number): number =>
    Math.floor(startOffset / searchParams.rowsPerPage);

  // change page with previous with 0 result in current page
  useEffect(() => {
    if (
      packages?.value.length === 0 &&
      searchParams.startOffset >= searchParams.rowsPerPage &&
      !isLoading
    ) {
      // Go to the previous page
      setSearchParams({
        ...searchParams,
        startOffset: searchParams.startOffset - searchParams.rowsPerPage,
      });
    }
  }, [packages?.value, isLoading]);

  useEffect(() => {
    let newParams = searchParams;

    // Update number of items per page based on user's preference
    if (account?.settings?.itemsPerPage !== undefined) {
      newParams.rowsPerPage = account?.settings?.itemsPerPage;
      setSearchParams(newParams);
    }
  }, [account?.settings]);

  const initialRuleChange = {
    show: false,
    hasError: false,
    item: {} as IPackage,
  };

  const [updateRuleParams, setUpdateRuleParams] = useState(initialRuleChange);

  const editRule = (item: IPackage) => {
    setUpdateRuleParams({
      ...updateRuleParams,
      show: true,
      item: item,
    });
  };

  // Add package rule api trigger
  const [addRuleTrigger] = useAddRuleMutation();

  // Update package rule api trigger
  const [updateRuleTrigger] = useUpdateRuleMutation();

  const [isAddingUpdatingRule, setIsAddingUpdatingRule] = useState(false);

  const addUpdateRule = async (item: IPackage, ruleTemplate: IRuleTemplate) => {
    if (!item.recordId || isAddingUpdatingRule) return;

    setIsAddingUpdatingRule(true);

    try {
      if (!item.rule) {
        await addRuleTrigger({
          applicationId: applicationId,
          version: item.version,
          ruleType: ruleTemplate.ruleType,
          callerIds: ruleTemplate.callerIds || [],
          locations: ruleTemplate.locations || [],
        }).unwrap();
      } else {
        await updateRuleTrigger({
          applicationId: applicationId,
          version: item.version,
          ruleType: ruleTemplate.ruleType,
          callerIds: ruleTemplate.callerIds || [],
          locations: ruleTemplate.locations || [],
        }).unwrap();
      }

      setUpdateRuleParams(initialRuleChange);
    } catch (error) {
      if (isFetchBaseQueryError(error) || isErrorWithMessage(error)) {
        setUpdateRuleParams({
          ...updateRuleParams,
          show: true,
          hasError: true,
        });
      }
    } finally {
      setIsAddingUpdatingRule(false);
    }
  };

  const getStatusClassName = (status: Status) => {
    let className = '';
    switch (status) {
      case Status.Published:
        className = 'versions__status--published';
        break;
      case Status.Cancelled:
        className = 'versions__status--cancelled';
        break;
      case Status.Released:
        className = 'versions__status--released';
        break;
      case Status.Paused:
        className = 'versions__status--paused';
        break;
      case Status.CheckFailed:
        className = 'versions__status--checkFailed';
        break;
      case Status.CheckInProgress:
        className = 'versions__status--checkInProgress';
        break;
      default:
        break;
    }
    return className;
  };

  const initialStatusChange = {
    show: false,
    hasError: false,
    newStatus: {} as Status,
    item: {} as IPackage,
  };

  const [updateStatusParams, setUpdateStatusParams] =
    useState(initialStatusChange);

  const handleStatusChange = (
    item: IPackage,
    event: ChangeEvent<HTMLSelectElement>
  ) => {
    setUpdateStatusParams({
      ...updateStatusParams,
      show: true,
      newStatus: event.target.value as Status,
      item: item,
    });
  };

  // Update package status api trigger
  const [updateStatusTrigger] = useUpdateStatusMutation();

  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const updateStatus = async (
    item: IPackage,
    newStatus: Status,
    comment: string
  ) => {
    if (!item.recordId || isUpdatingStatus) return;

    setIsUpdatingStatus(true);

    try {
      await updateStatusTrigger({
        applicationId: applicationId,
        version: item.version,
        status: newStatus,
        comment: comment,
      }).unwrap();

      setUpdateStatusParams(initialStatusChange);

      // Look for changes in the package status every 5 seconds
      setPackageToUpdate(item);
      setPoolingInterval(5000);
    } catch (error) {
      if (isFetchBaseQueryError(error) || isErrorWithMessage(error)) {
        setUpdateStatusParams({
          ...updateStatusParams,
          show: true,
          hasError: true,
        });
      }
    } finally {
      setIsUpdatingStatus(false);
    }
  };

  const initialCallerIdDetails = {
    show: false,
    item: {} as IRule,
  };

  const [callerIdDetailsParams, setCallerIdDetailsParams] = useState(
    initialCallerIdDetails
  );

  const onCallerIdDetails = (rule: IRule) => {
    setCallerIdDetailsParams({
      show: true,
      item: rule,
    });
  };

  const tableData = packages?.value.map((item: IPackage) => {
    const maps: { [key: string]: any } = {};
    maps[t('Version')] = {
      props: {
        label: item.version,
      },
      render: <Text classname="versions__number" />,
    };

    maps[t('Published on')] = {
      props: {
        label: `${formatDate(
          item.recordCreated,
          account?.settings?.language ?? 'en'
        )}`,
      },
      render: <Text />,
    };

    maps[t('Status')] = {
      props: {},
      render: (
        <div>
          <div
            className={
              'py-2 px-10 mt-5 versions__status ' +
              getStatusClassName(item.status)
            }
          >
            {item.status === Status.CheckFailed ? (
              <div className="pr-5">
                <img src={warningStatusIcon} alt="Warning about status" />
              </div>
            ) : (
              <></>
            )}
            <Text classname="versions__status__text" label={t(item.status)} />
            {item.status === Status.CheckFailed ? (
              <Tooltip
                text={t(
                  'An error occured with the selected product. Please contact your Carestream Dental Customer service.'
                )}
                icon={warningTooltipIcon}
              />
            ) : (
              <></>
            )}
          </div>
          <div>
            <p className="versions__status__comment">{item.comment}</p>
          </div>
        </div>
      ),
    };

    maps[t('Applied rule')] = {
      props: {},
      render: (
        <div className="versions__appliedRule">
          {item.rule?.ruleType === RuleType.CallerId ? (
            <div className="versions__appliedRule__details">
              <Text
                classname="mr-5"
                label={
                  item.rule?.ruleType === RuleType.CallerId &&
                  item.rule?.callerIds !== undefined
                    ? t('license key', { count: item.rule?.callerIds.length })
                    : ''
                }
              />
              {item.rule?.ruleType === RuleType.CallerId ? (
                <IconButton
                  alt={t('View license key details')}
                  onClick={() => onCallerIdDetails(item.rule)}
                  img={callerIdDetailsIcon}
                  tooltipText={t('View license key details')}
                />
              ) : (
                <></>
              )}
            </div>
          ) : (
            <GeographicalRuleDetails data={item.rule?.locations} />
          )}
          {item.status !== Status.Cancelled &&
            item.status !== Status.CheckInProgress && (
              <IconButton
                alt={t('Select rule')}
                onClick={() => {
                  editRule(item);
                }}
                img={editIcon}
                tooltipText={t('Select rule')}
              />
            )}
        </div>
      ),
    };

    maps[t('Actions')] = {
      props: {},
      render: (
        <ActionsMenu
          item={item}
          application={application}
          onChange={handleStatusChange}
        />
      ),
    };

    return maps;
  });

  // Update user settings api trigger
  const [updateSettingsTrigger] = useUpdateSettingsMutation();

  const onClickPagination = (startOffset: number, rowsPerPage: number) => {
    // Update search param
    setSearchParams({
      ...searchParams,
      rowsPerPage,
      startOffset,
    });

    // Save user's preference for number of items per page
    if (account?.settings?.itemsPerPage != rowsPerPage)
      updateSettingsTrigger({
        ...account?.settings,
        userId: account?.recordId,
        itemsPerPage: rowsPerPage,
      });
  };

  // Create colums titles
  const tableColumns = [
    { title: t('Version'), width: 50 },
    { title: t('Published on'), width: 75 },
    { title: t('Status'), width: 250 },
    { title: t('Applied rule'), width: 300 },
    { title: t('Actions'), width: 150 },
  ];

  return (
    <div>
      <div>
        <p className="csd_table-result">
          {`${packages?.['@odata.count']} ${
            packages?.['@odata.count'] === 1 ? t('result') : t('results')
          }`}
        </p>
      </div>
      <Table
        name="versions"
        rows={tableData}
        columns={tableColumns}
        nbResult={packages?.['@odata.count'] ? packages?.['@odata.count'] : 0}
        onClick={onClickPagination}
        displayNumber={false}
        selectedRowsPerPage={
          account?.settings?.itemsPerPage || defaultItemsPerPage
        }
        rowsPerPageOptions={[10, 50, 100]}
        labelRowsPerPage={t('Versions per page')}
        currentPage={getPage(searchParams.startOffset)}
      />
      <CallerIdDetailsDlg
        item={callerIdDetailsParams.item}
        show={callerIdDetailsParams.show}
        onClose={() => setCallerIdDetailsParams(initialCallerIdDetails)}
      />
      <SelectRuleDlg
        onCancel={() => setUpdateRuleParams(initialRuleChange)}
        onConfirm={addUpdateRule}
        application={application || ({} as IApplication)}
        item={updateRuleParams.item}
        show={updateRuleParams.show}
        hasError={updateRuleParams.hasError}
        disabled={isAddingUpdatingRule}
      />
      <ConfirmStatusDlg
        onCancel={() => setUpdateStatusParams(initialStatusChange)}
        onConfirm={updateStatus}
        application={application?.name || 'Unknown'}
        item={updateStatusParams.item}
        newStatus={updateStatusParams.newStatus}
        show={updateStatusParams.show}
        hasError={updateStatusParams.hasError}
        disabled={isUpdatingStatus}
      />
    </div>
  );
};

export default Versions;

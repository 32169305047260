import React from 'react';

import { useTranslation } from 'react-i18next';

import { NavMenu } from '@csdental/react-components';
import RuleTemplates from '@/page/RuleTemplates';
import Versions from '@/page/Versions';
import Users from "@/page/Users";

import versionsIconOn from '@/assets/icons/versions_management_on.svg';
import versionsIconOff from '@/assets/icons/versions_management_off.svg';
import rulesIconOn from '@/assets/icons/rule_templates_management_on.svg';
import rulesIconOff from '@/assets/icons/rule_template_management_off.svg';
import usersIconOn from '@/assets/icons/users_management_on.svg';
import usersIconOff from '@/assets/icons/users_management_off.svg';

import { csImagingSuiteId } from '@/constants'

const Home = () => {
  const { t } = useTranslation();

  const versionsSubNavItems = [{
    label: t('CS Imaging Suite'),
    render: Versions,
    renderProps: {applicationId: csImagingSuiteId},
  }];

  const ruleTemplatesSubNavItems = [{
    label: t('CS Imaging Suite'),
    render: RuleTemplates,
    renderProps: {applicationId: csImagingSuiteId},
  }];

  const navItems = [
     {
      label: t('Version management'),
      sub: versionsSubNavItems,
      iconOn: versionsIconOn,
      iconOff: versionsIconOff,
    },
    {
      label: t('Rule template administration'),
      sub: ruleTemplatesSubNavItems,
      iconOn: rulesIconOn,
      iconOff: rulesIconOff,
    },
    {
      label: t('User management'),
      render: Users,
      iconOn: usersIconOn,
      iconOff: usersIconOff
    }
  ];

  return (
    <div>
      <NavMenu items={navItems} vertical defaultIndex={0} />
    </div>
  );
};

export default Home;

import React from 'react';
import { createPortal } from 'react-dom';

const Loader: React.FC = () => {
  const portalRoot = document.getElementById('portal-root');

  if (!portalRoot) return null;

  const loader = (
    <div className='loaderContainer'>
      <div className='circle'></div>
    </div>
  );

  return createPortal(loader, portalRoot);
};

export default Loader;
import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IRuleTemplate } from './ruleTemplatesTypes';
import { configurationService } from '@/services/configuration';
import { TypeValueCount } from '@/common/types/queryTypes';

export const ruleTemplateApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['RuleTemplate'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getRuleTemplatesByApplication: builder.query<
	        TypeValueCount<IRuleTemplate>,
          {skip?: number; top?: number; applicationId: string}
	      >({
          query: (args) => {
            const encodedAplicationId= encodeURIComponent(args.applicationId);

            const params : Record<string, string> = {};
            params.$count = 'true';
            if (args.skip)
              params.$skip = args.skip.toString();
            if (args.top)
              params.$top = args.top.toString();
            params.$orderby = 'RecordLastUpdated desc';
            params.$expand = 'User';
            const queryParams = new URLSearchParams(params);

            const apiUrls = endPoints(api);
            const oDataQueryParams = `(applicationId=${encodedAplicationId})?${queryParams.toString()}`;
            const url = `${apiUrls.ruleTemplates.getByApplication}${oDataQueryParams}`;

            return url;
          },
          providesTags: (result) =>
            result?.value
              ? [
                  ...result.value.map(
                    ({ recordId }) =>
                      ({ type: 'RuleTemplate', recordId } as const)
                  ),
                  { type: 'RuleTemplate', id: 'LIST' },
                ]
              : [{ type: 'RuleTemplate', id: 'LIST' }],
        }),
        addRuleTemplate: builder.mutation<
          IRuleTemplate,
          Partial<IRuleTemplate & { duplicate: boolean }>
        >({
          query: (body) => ({
            url: endPoints(api).ruleTemplates.root,
            method: 'POST',
            body,
          }),
          invalidatesTags: (ruleTemplate) => [
            { type: 'RuleTemplate', id: 'LIST' },
            { type: 'RuleTemplate', recordId: ruleTemplate?.recordId },
          ],
        }),
        updateRuleTemplate: builder.mutation<
          IRuleTemplate,
          Partial<IRuleTemplate & { id: string }>
        >({
          query: (body) => ({
            url: endPoints(api).ruleTemplates.root,
            method: 'PUT',
            body,
          }),
          invalidatesTags: (ruleTemplate) => [
            { type: 'RuleTemplate', id: 'LIST' },
            { type: 'RuleTemplate', recordId: ruleTemplate?.recordId },
          ],
        }),
        deleteRuleTemplate: builder.mutation<void, { id: string }>({
          query: ({ id }) => ({
            url: `${endPoints(api).ruleTemplates.root}/${id}`,
            method: 'DELETE',
          }),
          invalidatesTags: (result, error, { id }) => [
            { type: 'RuleTemplate', id: 'LIST' },
            { type: 'RuleTemplate', recordId: id },
          ],
        }),
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useAddRuleTemplateMutation,
  useUpdateRuleTemplateMutation,
  useGetRuleTemplatesByApplicationQuery,
  useDeleteRuleTemplateMutation,
} = ruleTemplateApi;

import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IUser } from './usersTypes';
import { configurationService } from '@/services/configuration';
import { TypeValueCount } from '@/common/types/queryTypes';

export const userApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['User'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getUsers: builder.query<
        TypeValueCount<IUser>,
        {skip?: number; top?: number; filter ?: string}
      >({
        query: (args) => {
          const params : Record<string, string> = {};
          params.$count = 'true';
          if (args.skip)
            params.$skip = args.skip.toString();
          if (args.top)
            params.$top = args.top.toString();
          if (args.filter)
          {
            const valueUpperCase = args.filter?.toUpperCase();
            params.$filter = `(contains(FirstName,'${valueUpperCase}')
            or contains(LastName,'${valueUpperCase}')
            or contains(EmailAddress, '${valueUpperCase}'))`;
          }
          params.$orderby = 'RecordCreated desc';
          const queryParams = new URLSearchParams(params);

          const apiUrls = endPoints(api);
          const oDataQueryParams = `?${queryParams.toString()}`;
          const url = `${apiUrls.users.odataRoot}${oDataQueryParams}`;

          return url;
        },
        providesTags: (result) =>
          result?.value
            ? [
                ...result.value.map(
                  ({ recordId }) =>
                    ({ type: 'User', recordId } as const)
                ),
                { type: 'User', id: 'LIST' },
              ]
            : [{ type: 'User', id: 'LIST' }],
        }),
        addUser: builder.mutation<
          IUser,
          Partial<IUser>
        >({
          query: (body) => ({
            url: endPoints(api).users.apiRoot,
            method: 'POST',
            body,
          }),
          invalidatesTags: (user) => [
            { type: 'User', id: 'LIST' },
            { type: 'User', recordId: user?.recordId },
          ],
        }),
        updateUser: builder.mutation<
          IUser,
          Partial<IUser & { id: string }>
        >({
          query: (body) => ({
            url: endPoints(api).users.apiRoot,
            method: 'PUT',
            body,
          }),
          invalidatesTags: (user) => [
            { type: 'User', id: 'LIST' },
            { type: 'User', recordId: user?.recordId },
          ],
        }),
        deleteUser: builder.mutation<string, string>({
          query(id) {
            return {
              url: `${endPoints(api).users.apiRoot}?userId=${id}`,
              method: 'DELETE',
            };
          },
          invalidatesTags: () => [{ type: 'User', id: 'LIST' }],
        }),
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation
} =
userApi;

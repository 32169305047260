import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, ModalDialog } from '@csdental/react-components';

import { IUser } from '@/features/users/usersTypes';

type DeleteDlgProps = {
  user: IUser,
  onDelete: () => void,
  onCancel: () => void,
  show: boolean,
  hasError: boolean
}

const DeleteDlg = ({
  user,
  onDelete,
  onCancel,
  show,
  hasError
} : DeleteDlgProps) => {
  const { t } = useTranslation();

  return (
    <ModalDialog
      show={show}
      title=""
    >
      <div>
        <h2 className="delete-dlg__title">
          {t('Delete user')}
        </h2>
        <div className="delete-dlg__message">
          <p>
            {t('You are about to delete the following user')}:
          </p>
          <p className="delete-dlg__email">
            {user.emailAddress}
          </p>
        </div>
        {hasError &&
        <p className="error-message">
          {t('An error occurred when trying to delete the user.')}
        </p>
        }
        <div className="delete-dlg__buttons-container">
          <div className="delete-dlg__buttons">
            <Button
              theme="secondary"
              className="delete-dlg__button"
              label={t('Cancel')} onClick={onCancel}
            />
            <Button
              className="delete-dlg__button"
              label={t('Yes, delete')}
              onClick={onDelete}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default DeleteDlg;

/**
 * Format a date
 * Example: 01/31/1990
 */
const formatDate = (date: string, language: string) => {
  if (!date)
    return ''
    
  let year = date.slice(0,4)
  let month = date.slice(5,7)
  let day = date.slice(8,10)

  let result = '';

  switch (language) {
    case 'fr':
      result = `${day}/${month}/${year}`
      break;
    case 'en':
      result = `${month}/${day}/${year}`
      break;
    default:
      result = `${month}/${day}/${year}`
      break;
  }

  return result;
};

export {formatDate};
import React, { useEffect, useState } from 'react';

import { useGetAccountQuery, useUpdateSettingsMutation } from '@/features/account/accountApi';
import { useAddUserMutation, useDeleteUserMutation, useGetUsersQuery, useUpdateUserMutation } from '@/features/users/usersAPI';
import { IUser } from '@/features/users/usersTypes';

import { IDialogProps } from '@/common/types/dialogTypes';
import { isErrorWithMessage, isFetchBaseQueryError } from '@/common/helpers/errorTypeChecker';

import { defaultItemsPerPage } from '@/constants';

import { useTranslation } from 'react-i18next';
import {
  Table,
  Text,
  SearchBar,
  Button,
  IconButton
} from '@csdental/react-components';
import AddEditUserDlg from '@/components/AddEditUserDlg';
import DeleteDlg from '@/components/DeleteDlg/DeleteDlg';

import editIcon from '@/assets/icons/edit.svg';
import deleteIcon from '@/assets/icons/delete.svg';

export const Users = () => {
  const { t } = useTranslation();

  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();

  const [searchValueTmp, setSearchValueTmp] = useState<string>('');
  // Set the default search params
  const [searchParams, setSearchParams] = useState({
    rowsPerPage: defaultItemsPerPage,
    startOffset: 0,
    searchValue: ''
  });

  // Load users
  const { data: users, isLoading } =
    useGetUsersQuery({
      skip: searchParams.startOffset,
      top: searchParams.rowsPerPage,
      filter: searchParams.searchValue,
    });

  const defaultAddEditUserDlgState: IDialogProps<IUser> = {
    show: false,
    title: '',
    type: 'Create',
    data: null,
    error: ''
  };

  const [addEditUserDlgState, setAddEditUserDlgState] = useState<IDialogProps<IUser>>({...defaultAddEditUserDlgState});

  const defaultDeleteParams = {
    user: {} as IUser,
    show: false,
    hasError: false
  }

  const [deleteParams, setDeleteParams] = useState<{
    user: IUser;
    show: boolean;
    hasError: boolean;
  }>({ ...defaultDeleteParams });

  // Add user api trigger
  const [addUserTrigger] = useAddUserMutation();

  const onAddUser = async ({
    data,
    reset,
  }: {
    data: Partial<IUser>;
    reset: Function;
  }) => {
    try {
      const _ = await addUserTrigger(data).unwrap();
      setAddEditUserDlgState({ ...defaultAddEditUserDlgState });
      reset.call(this);
    } catch (ex) {
      var errorMessage = "";
      if (isFetchBaseQueryError(ex)) {
        errorMessage = 'error' in ex ? ex.error : JSON.stringify(ex.data);     
      } else if (isErrorWithMessage(ex)) {
        errorMessage = ex.message;
      }
      setAddEditUserDlgState((state) => ({...state, error: errorMessage }));
    }
  };

  // Update user api trigger
  const [updateUserTrigger] = useUpdateUserMutation();

  const onEditUser = async ({
    data,
    reset,
  }: {
    data: IUser;
    reset: Function;
  }) => {
    try {
      const _ = await updateUserTrigger({
        ...data,
      }).unwrap();
      setAddEditUserDlgState({ ...defaultAddEditUserDlgState });
      reset.call(this);
    } catch (ex) {
      var errorMessage = "";
      if (isFetchBaseQueryError(ex)) {
        errorMessage = 'error' in ex ? ex.error : JSON.stringify(ex.data);
      } else if (isErrorWithMessage(ex)) {
        errorMessage = ex.message;    
      }
      setAddEditUserDlgState((state) => ({...state, show: true, error: errorMessage }));
    }
  };

  // Delete User Api trigger
  const [deleteUserTrigger] = useDeleteUserMutation();

  const onDelete = async () => {
    if (!deleteParams.user.recordId) return;
    try {
      await deleteUserTrigger(deleteParams.user.recordId).unwrap();
      setDeleteParams({ ...defaultDeleteParams });
    } catch (error) {
      if (isFetchBaseQueryError(error) || isErrorWithMessage(error)) {
        setDeleteParams((state) => ({
          ...state,
          hasError: true
        }));
      }
    }
  };

  // Cancel user creation/edition/deletion
  const onCancel = () => {
    setAddEditUserDlgState({ ...defaultAddEditUserDlgState });
  };

  const getPage = (startOffset: number): number => Math.floor(startOffset / searchParams.rowsPerPage);

  // change page with previous with 0 result in current page
  useEffect(() => {
    if (
      users?.value.length === 0 &&
      searchParams.startOffset >= searchParams.rowsPerPage &&
      !isLoading
    ) {
      // Go to the previous page
      setSearchParams({
        ...searchParams,
        startOffset: searchParams.startOffset - searchParams.rowsPerPage,
      });
    }
  }, [users?.value, isLoading]);

  useEffect(() => {
    let newParams = searchParams;

    // Update number of items per page based on user's preference
    if (account?.settings?.itemsPerPage !== undefined) {
      newParams.rowsPerPage = account?.settings?.itemsPerPage;
      setSearchParams(newParams);
    }
  }, [account?.settings]);

  // Update user settings api trigger
  const [updateSettingsTrigger] = useUpdateSettingsMutation();

  const onClickPagination = (startOffset: number, rowsPerPage: number) => {
    // Update search param
    setSearchParams({
      ...searchParams,
      rowsPerPage,
      startOffset,
    });

    // Save user's preference for number of items per page
    if (account?.settings?.itemsPerPage != rowsPerPage)
      updateSettingsTrigger({ ...account?.settings, userId: account?.recordId, itemsPerPage: rowsPerPage });
  }

  const onSearch = () => {
    setSearchParams({
      ...searchParams,
      startOffset: 0,
      searchValue: searchValueTmp,
    });
  };

  // Columns titles
  const userNameColumn = t('User name');
  const emailColumn = t('Email');
  const actionsColumn = t('Actions');

  // Create titles columns
  const tableColumns = [
    { title: userNameColumn, width: 75 },
    { title: emailColumn, width: 75 },
    { title: actionsColumn, width: 75 }
  ];

  const tableData = users?.value.map((user: IUser) => {
    const maps: { [key: string]: any } = {};

    maps[userNameColumn] = {
      props: {
        label: `${user.lastName} ${user.firstName}`,
      },
      render: <Text />,
    };

    maps[emailColumn] = {
      props: {
        label: user.emailAddress
      },
      render: <Text />,
    };

    maps[actionsColumn] = {
      props: {},
      render: (
        <div>
          <IconButton
            alt={t('Edit user')}
            onClick={() => {
              setAddEditUserDlgState((state) => ({
                ...state,
                title: t('Edit user'),
                show: true,
                type: 'Save',
                data: {
                  ...user,
                } as IUser,
                onCancel: onCancel,
                onEdit: onEditUser,
              }));
            }}
            img={editIcon}
            tooltipText={t('Edit user')}
          />
          {user.emailAddress !== account?.emailAddress &&
          <IconButton
            alt={t('Delete user')}
            onClick={() => setDeleteParams({user: user, show: true, hasError: false })}
            img={deleteIcon}
            tooltipText={t('Delete user')}
          />}
        </div>
      ),
    };

    return maps;
  });

  return (
    <div>
      <div className="users__searchbar">
        <SearchBar
          className="users__searchbar_input"
          placeholder={`${t('user name')}, ${t('email')}`}
          onChange={(value: string) => setSearchValueTmp(value)}
          onSearch={onSearch}
          onCancel={() => {
            setSearchParams({
              ...searchParams,
              startOffset: 0,
              searchValue: '',
            });
            setSearchValueTmp('');
          }}
          value=""
        />
        <Button
          className="users__searchbar__btn"
          label={t('Search')}
          onClick={onSearch}
        />
      </div>
      <div className="users__add-and-results">
        <div className="users__add">
          <Button
            label={`+ ${t('Add user')}`}
            onClick={() => {
              setAddEditUserDlgState((state) => ({
                ...state,
                title: t('Add user'),
                show: true,
                onCancel: onCancel,
                onCreate: onAddUser,
              }));
            }}
          />
        </div>
        <div>
          <p className="csd_table-result">
            {`${users?.['@odata.count']} ${users?.['@odata.count'] === 1
              ? t('result')
              : t('results')}`}
          </p>
        </div>
      </div>
      <Table
        name="users"
        rows={tableData}
        columns={tableColumns}
        nbResult={users?.['@odata.count'] ? users?.['@odata.count'] : 0}
        onClick={onClickPagination}
        displayNumber={false}
        selectedRowsPerPage={account?.settings?.itemsPerPage || defaultItemsPerPage}
        rowsPerPageOptions={[10, 50, 100]}
        labelRowsPerPage={t('Users per page')}
        currentPage={getPage(searchParams.startOffset)}
      />
      <AddEditUserDlg
        {...addEditUserDlgState}
      />
      <DeleteDlg
        onCancel={() => setDeleteParams({ ...defaultDeleteParams })}
        onDelete={onDelete}
        show={deleteParams.show}
        hasError={deleteParams.hasError}
        user={deleteParams.user}
      />
    </div>
  );
};

export default Users;

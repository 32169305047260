import React, { useEffect } from 'react';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { useAppDispatch } from '@/common/hooks/state';
import { signIn, signInSuccess, signOut } from '@/features/auth/authSlice';
import authService from '@/features/auth/authService';

type AuthproviderProps = {
  logMessages: boolean;
  children?: React.ReactNode;
};

const AuthProvider: React.FC<AuthproviderProps> = ({
  logMessages,
  children,
}) => {
  const instance = authService.msalClient;
  const dispatch = useAppDispatch();
  const { inProgress } = useMsal();

  // Handle MSAL events
  useEffect(() => {
    // Add MSAL callback event handler
    const callbackId = instance.addEventCallback(
      async (event: EventMessage) => {
        if (logMessages) {
          console.log(
            `%cMSAL%c Event: ${event.eventType}. Interaction: ${event.interactionType}. Error: ${event.error}`,
            authService?.consoleTextStyles.Title,
            authService?.consoleTextStyles.Unset,
          );
        }

        // Process the MSAL event
        switch (event.eventType) {
          case EventType.LOGIN_START:
          case EventType.LOGOUT_START:
            dispatch(signOut({ timedOut: false }));
            break;

          case EventType.LOGIN_SUCCESS: {
            const currentAccounts = instance.getAllAccounts();

            if (currentAccounts.length === 0) {
              dispatch(signOut({ timedOut: false }));
            } else {
              dispatch(signIn());

              // Set the auth state
              const { accessToken } = event.payload as AuthenticationResult;
              dispatch(signInSuccess({ token: accessToken }));
            }
            break;
          }
          case EventType.LOGIN_FAILURE:
            console.groupCollapsed(
              "%cLogin failed. There's likely a configuration issue.",
              'background: #faa; color: red',
            );
            console.error(event.error);
            console.groupEnd();
            break;
          default:
            break;
        }
      },
    );

    // Remove event handler
    return function detachEvent() {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, [instance, logMessages, dispatch]);

  return (
    <MsalProvider instance={instance}>
      {inProgress !== InteractionStatus.None ? (
        <div style={{ height: '100vh' }}>
          <div className="centered">
            <p>Please Wait...</p>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </MsalProvider>
  );
};

export default AuthProvider;

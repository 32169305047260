import React, { ChangeEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { IPackage } from '@/features/packages/packagesTypes';
import { Status, getActionFromStatus } from '@/common/enums/status';
import { IApplication } from '@/features/applications/applicationsTypes';

export type ActionsMenuProps =
{
  item: IPackage;
  application?: IApplication;
  onChange: (item: IPackage, event: ChangeEvent<HTMLSelectElement>) => void;
}

export const ActionsMenu = ({item, application, onChange}: ActionsMenuProps) => {
  const { t } = useTranslation();

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const newValue = e.target.value;
    if (newValue) {
      onChange(item, e);
    }
  };


  const getStatusesList = (status: string) => {
    const statuses : Status[] = [];
    switch (status) {
      case Status.Published:
        if (!application?.isRuleRequired || item.rule !== null)
          statuses.push(Status.Released);
        statuses.push(Status.Cancelled);
        break;
      case Status.Released:
        statuses.push(Status.Paused);
        statuses.push(Status.Cancelled);
        break;
      case Status.Paused:
        if (!application?.isRuleRequired || item.rule !== null)
          statuses.push(Status.Released);
          statuses.push(Status.Cancelled);
        break;
      case Status.CheckFailed:
      case Status.CheckInProgress:
      case Status.Cancelled:
      default:
        break;
    }
    return statuses;
  }

  const statuses = getStatusesList(item.status);

  return (
    <div>
      {!statuses.length ?
        <div/>
        :
        <select
          className="csdInput actions_menu"
          value=""
          onChange={handleSelectChange}
        >
          <option disabled selected value="">
            {t('Select')}
          </option>
          {statuses.map((status) => (
            <option
              value={status}
              key={status}
            >
              {t(getActionFromStatus(status))}
            </option>
          ))}
        </select>
      }
    </div>
  );
}

export default ActionsMenu;

import { useGetAccountQuery } from '@/features/account/accountApi';
import Unauthorized from '@/page/Unauthorized';
import React from 'react';

type AuthentificationWrapperProps = {
  children?: React.ReactNode;
};
export const AuthentificationWrapper: React.FC<
  AuthentificationWrapperProps
> = ({ children }: AuthentificationWrapperProps) => {
  const { data: account, isLoading } = useGetAccountQuery();
  if (isLoading) {
    return <></>;
  }
  if (account) {
    
    return <>{children}</>;
  }
  return <Unauthorized />;
};

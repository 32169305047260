import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import SessionTimeoutDialog from './IdleTimerPrompt';

export interface IdleTimerProps {
  /**
   * Number of minutes of inactivity before the timeout is triggered.
   * Defaults to 30 minutes.
   */
  timeout?: number;
  /**
   * Number of seconds the timeout prompt is displayed before a timeout is triggered.
   * A value of 0 will result in no prompt being displayed.
   * Defaults to 30 seconds.
   */
  promptTimeout?: number;
  /**
   * The onTimeout method is called when:
   * 1. The timeout duration pass with no user action
   * 2. The user confirmed to end the session
   */
  // eslint-disable-next-line no-unused-vars
  onTimeout?: (isTimedOut: boolean) => void;
}

export function IdleTimer({ ...inputProps }: IdleTimerProps) {
  // Set timeout values
  const timeout = inputProps.timeout ? (1000 * 60 * inputProps.timeout) : (1000 * 60 * 30);
  const promptTimeout = inputProps.promptTimeout ? (1000 * inputProps.promptTimeout) : (1000 * 30);

  // Modal open state
  const [open, setOpen] = useState(false);

  // Time before idle
  const [remaining, setRemaining] = useState(0);

  const onPrompt = () => {
    // onPrompt will be called after the timeout value is reached
    setOpen(true);
    setRemaining(promptTimeout);
  };

  const onIdle = () => {
    // onIdle will be called after the promptTimeout is reached.
    setOpen(false);
    setRemaining(0);
    if (inputProps.onTimeout) inputProps.onTimeout(true);
  };

  const onActive = () => {
    // onActive will only be called if `reset()` is called while `isPrompted()`
    // is true.
    setOpen(false);
    setRemaining(0);
  };

  const { getRemainingTime, isPrompted, activate } = useIdleTimer({
    timeout,
    promptTimeout,
    onPrompt,
    onIdle,
    onActive,
  });

  const handleContinue = () => {
    setOpen(false);
    activate();
  };

  const handleLogout = () => {
    setOpen(false);
    if (inputProps.onTimeout) inputProps.onTimeout(false);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted]);

  return (
    <SessionTimeoutDialog
      countdown={remaining}
      onContinue={handleContinue}
      onLogout={() => handleLogout()}
      open={open}
    />
  );
}

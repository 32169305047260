import {
  Configuration,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from '@azure/msal-browser';
import { configurationService, RawConfig } from '@/services/configuration';

class AuthService {
  msalConfig: Configuration;

  msalClient: PublicClientApplication;

  authScopes: { userStandard: string };

  loginRequest: RedirectRequest;

  consoleTextStyles: RawConfig['auth']['consoleTextStyles'];

  constructor() {
    this.msalConfig = configurationService.getMSALConfig();
    const configuration = configurationService.getConfiguration();
    this.msalClient = new PublicClientApplication(this.msalConfig);
    this.consoleTextStyles = {
      Title: configuration?.auth?.consoleTextStyles?.Title,
      Unset: configuration.auth?.consoleTextStyles?.Unset,
    };
    this.authScopes = {
      userStandard: configuration?.auth?.scopes?.userStandard,
    };

    // Add here scopes for id token to be used at MS Identity Platform endpoints.
    this.loginRequest = {
      scopes: [this.authScopes.userStandard],
    };
  }

  async signIn() {
    this.msalClient
      .loginRedirect(this.loginRequest)
      .catch((error) => console.log(error));
  }

  signOut() {
    this.msalClient.logoutRedirect();
  }

  /** Get a new auth token for SUMSserver user standard */
  async getUserStandardAuthToken() {
    const token = await this.getAuthTokenForScopes([
      this.authScopes.userStandard,
    ]);
    return token;
  }

  /** Get a new auth token for the specified scopes */
  async getAuthTokenForScopes(scopes: string[]) {
    const accounts = this.msalClient.getAllAccounts();

    if (accounts.length > 0) {
      const request: SilentRequest = {
        scopes,
        account: accounts[0],
      };

      const accessToken = await this.msalClient
        .acquireTokenSilent(request)
        .then((response) => response.accessToken)
        .catch((error) => {
          // Do not fallback to interaction when running outside the context of MsalProvider.
          // Interaction should always be done inside context.
          console.log(error);
          return null;
        });

      return accessToken;
    }

    return null;
  }
}

const authService = new AuthService();

export default authService;

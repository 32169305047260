// Define the API endpoints
const endPoints = (apiconfig: any) => ({
  root: {
    healthCheck: 'healthcheck',
  },
  account: {
    root: `${apiconfig.rest}Account`,
    updateSettings: `${apiconfig.rest}Account/UpdateSettings`,
  },
  rules: {
    root: `${apiconfig.rest}Rules`
  },
  ruleTemplates: {
    root: `${apiconfig.rest}RuleTemplates`,
    getByApplication: `${apiconfig.OData}RuleTemplates/GetByApplication`,
  },
  callerId:{
    validateCallerId: `${apiconfig.rest}CallerIds/ValidateCallerId`
  },
  packages: {
    getByApplication: `${apiconfig.OData}Packages/GetPackagesForApplication`,
    updateStatus: `${apiconfig.rest}Packages/UpdateStatus`,
    getPackageStatus: `${apiconfig.rest}Packages/GetStatus`
  },
  applications: {
    root: `${apiconfig.rest}Applications`,
  },
  users: {
    odataRoot: `${apiconfig.OData}Users`,
    apiRoot: `${apiconfig.rest}Users`
  },
  regions:{
    root:`${apiconfig.rest}Regions`
  }
});

export default endPoints;

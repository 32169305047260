import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { IPackage } from './packagesTypes';
import { configurationService } from '@/services/configuration';
import { TypeValueCount } from '@/common/types/queryTypes';

export const packageApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['Package'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        getPackagesByApplication: builder.query<TypeValueCount<IPackage>,
        {skip: number, top: number, applicationId: string}>({
          query: (args) => {
            const encodedAplicationId= encodeURIComponent(args.applicationId);

            const params : Record<string, string> = {};
            params.$count = 'true';
            params.$skip = args.skip.toString();
            params.$top = args.top.toString();
            params.$orderby = 'RecordCreated desc';
            params.$expand = 'Rule';
            const queryParams = new URLSearchParams(params);

            const apiUrls = endPoints(api);
            const oDataQueryParams = `(applicationId=${encodedAplicationId})?${queryParams.toString()}`;
            const url = `${apiUrls.packages.getByApplication}${oDataQueryParams}`;

            return url;
          },
          providesTags: (result) => (result?.value
            ? [...result.value.map(({ recordId }) => ({ type: 'Package', recordId } as const)),
            { type: 'Package', id: 'LIST' }]
            : [{ type: 'Package', id: 'LIST' }]),
        }),
        updateStatus: builder.mutation<IPackage, Partial<IPackage>>({
          query(data) {
            const { ...body } = data;
            return {
              url: endPoints(api).packages.updateStatus,
              method: 'PUT',
              body,
            };
          },
          invalidatesTags: ['Package'],
        }),
        getPackageStatus: builder.query<Partial<IPackage>, {item: IPackage}>({
          query: (args) => {
            const encodedAplicationId= encodeURIComponent(args.item.applicationId);

            const params : Record<string, string> = {};
            params.applicationId = encodedAplicationId;
            params.version = args.item.version
            const queryParams = new URLSearchParams(params);

            const apiUrls = endPoints(api);
            const url = `${apiUrls.packages.getPackageStatus}?${queryParams.toString()}`;

            return url;
          },
        })
      };
    },
  });

// Action creators are generated for each case reducer function
export const {
  useGetPackagesByApplicationQuery,
  useUpdateStatusMutation,
  useGetPackageStatusQuery
} =
packageApi;

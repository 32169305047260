import React from 'react';
import './AppInfoDlg.scss';
import { ModalDialog, HealthCheck, IconButton } from '@csdental/react-components';
import { IAppInfoDlgProps } from '@/common/types/dialogTypes';
import closeIcon from '@/assets/icons/close.svg';

const AppInfoDlg = ({ ...inputProps }: IAppInfoDlgProps) => (
  <ModalDialog show={inputProps.show} title="">
    <div className="healthCheckDlg">
      <div className="closeIcon">
        <IconButton
          alt="Close"
          onClick={inputProps.onClose}
          img={closeIcon}
        />
      </div>
      <HealthCheck {...inputProps.healthCheckInfo} />
    </div>
  </ModalDialog>
);

export default AppInfoDlg;

import { apiSlice } from '@/api/apiSlice';
import endPoints from '@/api/endpoints';
import { configurationService } from '@/services/configuration';

export const callerIdApi = apiSlice
  .enhanceEndpoints({
    addTagTypes: ['callerId'],
  })
  .injectEndpoints({
    endpoints: (builder) => {
      const api = configurationService.getApiEndpoints();
      return {
        validateCallerId:builder.mutation<
          string,
          { Value: string;LicenseType:string; }
        >({
          query: (body) => ({
            url: endPoints(api).callerId.validateCallerId,
            method: 'POST',
            body,
          }),
        }),
      };
    },
  });

// Action creators are generated for each case reducer function
export const { useValidateCallerIdMutation } = callerIdApi;

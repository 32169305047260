import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Button, ModalDialog, TextField } from '@csdental/react-components';

import { Status, getActionFromStatus } from '@/common/enums/status';
import { IPackage } from '@/features/packages/packagesTypes';

type ConfirmStatusDlgProps = {
  onConfirm: (item: IPackage, status: Status, comment: string) => void,
  onCancel: () => void,
  application: string,
  item: IPackage,
  newStatus: Status,
  show: boolean,
  hasError: boolean,
  disabled: boolean
}

const ConfirmStatusDlg = ({
  onConfirm,
  onCancel,
  application,
  item,
  newStatus,
  show,
  hasError,
  disabled
} : ConfirmStatusDlgProps) => {
  const { t } = useTranslation();

  const maxChar = 120;

  const [comment, setComment] = useState<string>("");

  const action = getActionFromStatus(newStatus);
  const title = `${action} version`;
  const info = `You are about to ${action.toLocaleLowerCase()} this version`;

  return (
    <ModalDialog show={show} title={t(title)}>
      <div className="confirm-status-dlg__text">
        <p>{t(info)} :</p>
        <p className="confirm-status-dlg__version">{application} - {t('Version')} {item.version}</p>
        <p>{t('Comment')} *</p>
        <TextField
          className="confirm-status-dlg__comment"
          multiline={true}
          inputProps={{
            maxLength: maxChar
          }}
          value={comment}
          onChange={(e) =>(setComment(e.target.value))}
        ></TextField>
        <p className="confirm-status-dlg__comment__legend">
          * <Trans>{{maxChar}} characters max</Trans>
        </p>
        {hasError && <p className="error-message">{t('An error occurred when trying to update the version status.')}</p>}
        <div>
          <div className="confirm-status-dlg__button-group">
            <Button
              theme="secondary"
              className="confirm-status-dlg__button-group__button"
              label={t('Cancel')}
              onClick={() => {
                onCancel();
                setComment("");
              }}
            />
            <Button
              className="confirm-status-dlg__button-group__button"
              label={t('Yes, confirm')}
              onClick={() => {
                onConfirm(item, newStatus, comment);
                setComment("");
              }}
              disable={disabled}
            />
          </div>
        </div>
      </div>
    </ModalDialog>
  );
};

export default ConfirmStatusDlg;

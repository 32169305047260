import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, IconButton, Table, Text } from '@csdental/react-components';

import {
  useAddRuleTemplateMutation,
  useGetRuleTemplatesByApplicationQuery,
  useUpdateRuleTemplateMutation,
  useDeleteRuleTemplateMutation,
} from '@/features/ruleTemplates/ruleTemplatesAPI';
import {
  useGetAccountQuery,
  useUpdateSettingsMutation,
} from '@/features/account/accountApi';
import { IRuleTemplate } from '@/features/ruleTemplates/ruleTemplatesTypes';

import AddEditRuleTemplateDlg from '@/components/AddEditRuleTemplateDlg';
import DeleteRuleTemplateDlg from '@/components/DeleteRuleTemplateDlg';
import Loader from '@/components/Loader';
import CallerIdDetailsDlg from '@/components/CallerIdDetailsDlg';

import { IDialogProps } from '@/common/types/dialogTypes';
import { RuleType } from '@/common/enums/RuleType';
import { formatDate } from '@/utils/format';
import { csImagingSuiteId, defaultItemsPerPage } from '@/constants';

import editIcon from '@/assets/icons/edit.svg';
import duplicateIcon from '@/assets/icons/duplicate.svg';
import callerIdDetailsIcon from '@/assets/icons/magnifying_glass.svg';
import deleteIcon from '@/assets/icons/delete.svg';
import GeographicalRuleDetails from '@/components/GeographicalRuleDetails';

export type RuleTemplatesProps = {
  applicationId: string;
};

const defaultAddEditRuleTemplateDlgState: IDialogProps<IRuleTemplate> = {
  show: false,
  title: '',
  type: 'Create',
  data: null,
};

const defaultDeleteDlgState = {
  show: false,
  template: {} as IRuleTemplate,
  errorMessage: '',
};

export const RuleTemplates = ({ applicationId }: RuleTemplatesProps) => {
  const { t } = useTranslation();
  const [addRuleTemplateMutationTrigger] = useAddRuleTemplateMutation();
  const [UpdateRuleTemplateMutationTrigger] = useUpdateRuleTemplateMutation();
  const [deleteRuleTemplateMutationTrigger] = useDeleteRuleTemplateMutation();

  const [addEditRuleTemplateDlgState, setAddEditRuleTemplateDlgState] =
    useState<IDialogProps<IRuleTemplate>>({
      ...defaultAddEditRuleTemplateDlgState,
    });

  const [deleteDialogState, setDeleteDialogState] = useState(
    defaultDeleteDlgState
  );

  // Get the account of the logged in user
  const { data: account } = useGetAccountQuery();

  // Set the default search params
  const [searchParams, setSearchParams] = useState({
    rowsPerPage: defaultItemsPerPage,
    startOffset: 0,
  });

  // loading state
  const [loading, setLoading] = useState(false);

  // Load rule templates for the current application
  const { data: templates, isLoading } = useGetRuleTemplatesByApplicationQuery({
    applicationId: applicationId,
    skip: searchParams.startOffset,
    top: searchParams.rowsPerPage,
  });
  // Update user settings api trigger
  const [updateSettingsTrigger] = useUpdateSettingsMutation();

  const getPage = (startOffset: number): number =>
    Math.floor(startOffset / searchParams.rowsPerPage);

  // change page with previous with 0 result in current page
  useEffect(() => {
    if (
      templates?.value.length === 0 &&
      searchParams.startOffset >= searchParams.rowsPerPage &&
      !isLoading
    ) {
      // Go to the previous page
      setSearchParams({
        ...searchParams,
        startOffset: searchParams.startOffset - searchParams.rowsPerPage,
      });
    }
  }, [templates?.value, isLoading]);

  useEffect(() => {
    let newParams = searchParams;

    // Update number of items per page based on user's preference
    if (account?.settings?.itemsPerPage !== undefined) {
      newParams.rowsPerPage = account?.settings?.itemsPerPage;
      setSearchParams(newParams);
    }
  }, [account?.settings]);

  const onCancel = () => {
    setAddEditRuleTemplateDlgState({ ...defaultAddEditRuleTemplateDlgState });
  };

  const onEdit = async ({
    data,
    reset,
  }: {
    data: IRuleTemplate;
    reset: Function;
  }) => {
    try {
      setLoading(true);
      const body= {...data}
      if(data.ruleType === RuleType.CallerId) {
        delete body.locations;
      }else {
        delete body.callerIds;
      }
      const _ = await UpdateRuleTemplateMutationTrigger({
        ...body,
        id: body.recordId,
      }).unwrap();
      setAddEditRuleTemplateDlgState({ ...defaultAddEditRuleTemplateDlgState });
      reset.call(this);
    } catch (ex) {
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const onCreate = async ({
    data,
    reset,
  }: {
    data: IRuleTemplate;
    reset: Function;
  }) => {
    try {
      setLoading(true);
      const body= {...data}
      if(data.ruleType === RuleType.CallerId) {
        delete body.locations;
      }else {
        delete body.callerIds;
      }
      const _ = await addRuleTemplateMutationTrigger({
        ...body,
        applicationId: applicationId,
      }).unwrap();
      setAddEditRuleTemplateDlgState({ ...defaultAddEditRuleTemplateDlgState });
      reset.call(this);
    } catch (ex) {
      throw ex;
    } finally {
      setLoading(false);
    }
  };

  const onConfirmDelete = async () => {
    try {
      setLoading(true);
      if (deleteDialogState.template) {
        await deleteRuleTemplateMutationTrigger({
          id: deleteDialogState.template.recordId,
        }).unwrap();
        setDeleteDialogState(defaultDeleteDlgState);
      }
    } catch (ex) {
      setDeleteDialogState((prevState) => ({
        ...prevState,
        errorMessage: t(
          'An error occurred while deleting the template. Please try again.'
        ),
      }));
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (ruleTemplate: IRuleTemplate) => {
    setDeleteDialogState({
      show: true,
      template: ruleTemplate,
      errorMessage: '',
    });
  };

  const onDuplicate = async (ruleTemplate: IRuleTemplate) => {
    try {
      setLoading(true);
      const _ = await addRuleTemplateMutationTrigger({
        ...ruleTemplate,
        applicationId: applicationId,
        duplicate: true,
      }).unwrap();
    } catch (ex) {
    } finally {
      setLoading(false);
    }
  };

  const initialCallerIdDetails = {
    show: false,
    item: {} as IRuleTemplate,
  };

  const [callerIdDetailsParams, setCallerIdDetailsParams] = useState(
    initialCallerIdDetails
  );

  const onCallerIdDetails = (ruleTemplate: IRuleTemplate) => {
    setCallerIdDetailsParams({
      show: true,
      item: ruleTemplate,
    });
  };

  const tableData = templates?.value.map((item: IRuleTemplate) => {
    const maps: { [key: string]: any } = {};
    const ruleDetailsRender =
      item.ruleType === RuleType.CallerId ? (
        <div className="ruleTemplate__details">
          <Text
            classname="mr-5"
            label={`${
              item.ruleType === RuleType.CallerId &&
              item.callerIds !== undefined
                ? t('license key', { count: item.callerIds.length })
                : ''
            }`}
          />
          {item.ruleType === RuleType.CallerId ? (
            <IconButton
              alt={t('View license key details')}
              onClick={() => onCallerIdDetails(item)}
              img={callerIdDetailsIcon}
              tooltipText={t('View license key details')}
            />
          ) : (
            <></>
          )}
        </div>
      ) : (
        <GeographicalRuleDetails data={item.locations} />
      );
    maps[t('Name')] = {
      props: {
        label: item.name,
      },
      render: <Text />,
    };

    maps[t('Last edit')] = {
      props: {},
      render: (
        <div>
          <Text
            label={`${t('Last modified on')}: ${formatDate(
              item.recordLastUpdated,
              account?.settings?.language ?? 'en'
            )}`}
          />
          <Text
            label={`${t('By')}: ${item.user.firstName} ${item.user.lastName}`}
          />
        </div>
      ),
    };

    maps[t('Type')] = {
      props: {
        label: `${
          item.ruleType === RuleType.CallerId &&
          applicationId === csImagingSuiteId
            ? t('License key')
            : item.ruleType === RuleType.LocationAndPercentage
            ? t('Geographical zone')
            : item.ruleType
        }`,
      },
      render: <Text />,
    };

    maps[t('Rule details')] = {
      props: {},
      render: ruleDetailsRender,
    };
    maps[t('Actions')] = {
      props: {},
      render: (
        <div>
          <IconButton
            alt={t('Delete rule template')}
            onClick={() => onDelete(item)}
            img={deleteIcon}
            tooltipText={t('Delete rule template')}
          />
          <IconButton
            alt={t('Duplicate rule template')}
            onClick={() => onDuplicate(item)}
            img={duplicateIcon}
            tooltipText={t('Duplicate rule template')}
          />
          <IconButton
            alt={t('Edit rule template')}
            onClick={() => {
              setAddEditRuleTemplateDlgState({
                ...addEditRuleTemplateDlgState,
                title: t('Edit rule template'),
                show: true,
                type: 'Save',
                data: {
                  ...item,
                } as IRuleTemplate,
                onCancel: onCancel,
                onEdit: onEdit,
              });
            }}
            img={editIcon}
            tooltipText={t('Edit rule template')}
          />
        </div>
      ),
    };
    return maps;
  });

  const onClickPagination = (startOffset: number, rowsPerPage: number) => {
    // Update search param
    setSearchParams({
      ...searchParams,
      rowsPerPage,
      startOffset,
    });

    // Save user's preference for number of items per page
    if (account?.settings?.itemsPerPage != rowsPerPage)
      updateSettingsTrigger({
        ...account?.settings,
        userId: account?.recordId,
        itemsPerPage: rowsPerPage,
      });
  };

  // Create colums titles
  const tableColumns = [
    { title: t('Name'), width: 150 },
    { title: t('Last edit'), width: 150 },
    { title: t('Type'), width: 150 },
    { title: t('Rule details'), width: 150 },
    { title: t('Actions'), width: 150 },
  ];

  const getApplicationId = () => {
    return applicationId;
  };
  return (
    <>
      {loading ? <Loader /> : <></>}
      <div className="ruleTemplate__control__container">
        <Button
          className="ruleTemplate__control__container__button"
          label={`+ ${t('Create rule template')}`}
          onClick={() => {
            setAddEditRuleTemplateDlgState({
              ...addEditRuleTemplateDlgState,
              title: t('Create rule template'),
              show: true,
              onCancel: onCancel,
              onCreate: onCreate,
            });
          }}
        />
        <p className="csd_table-result">
          {`${templates?.['@odata.count']} ${
            templates?.['@odata.count'] === 1 ? t('result') : t('results')
          }`}
        </p>
      </div>
      <Table
        name="ruleTemplates"
        rows={tableData}
        columns={tableColumns}
        nbResult={templates?.['@odata.count'] ? templates?.['@odata.count'] : 0}
        onClick={onClickPagination}
        displayNumber={false}
        selectedRowsPerPage={
          account?.settings?.itemsPerPage || defaultItemsPerPage
        }
        rowsPerPageOptions={[10, 50, 100]}
        labelRowsPerPage={t('Rule templates per page')}
        currentPage={getPage(searchParams.startOffset)}
      />
      <AddEditRuleTemplateDlg
        {...addEditRuleTemplateDlgState}
        getApplicationId={getApplicationId}
      />
      <CallerIdDetailsDlg
        item={callerIdDetailsParams.item}
        show={callerIdDetailsParams.show}
        onClose={() => setCallerIdDetailsParams(initialCallerIdDetails)}
      />
      <DeleteRuleTemplateDlg
        show={deleteDialogState.show}
        template={deleteDialogState.template}
        onClose={() => setDeleteDialogState(defaultDeleteDlgState)}
        onDelete={onConfirmDelete}
        errorMessage={deleteDialogState.errorMessage}
      />
    </>
  );
};

export default RuleTemplates;
